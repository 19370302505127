/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Container,
  Nav,
  Navbar,
  Image,
  Row,
  Col,
  Button,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
  Form,
  Dropdown,
  Spinner,
} from 'react-bootstrap';
import { BsPersonCircle } from 'react-icons/bs';
import { MdOutlineManageAccounts } from 'react-icons/md';
import { FiLogIn } from 'react-icons/fi';

import { removeCookie } from 'typescript-cookie';
import { BsFillPersonFill } from 'react-icons/bs';
import AxiosInstanceUserServer from './axios/AxiosInstanceUserServer';

import './Header.css';
import ImageContext from '../ImageContext';

// Tutorial link: https://www.codemzy.com/blog/react-sticky-header-disappear-scroll

// This function used to detect the scroll direction and return two values 1-down 2-up, if the scroll direction up ==> the header
// will appear , and if the scroll direction down ==> the header will disappear.
function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      var direction;
      direction = scrollY > lastScrollY ? 'down' : 'up';
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

const Header = (props) => {
  const {onboardingImages} = useContext(ImageContext)

  const [pagePath, setPagePath] = useState(window.location.pathname);
  const [expanded, setExpanded] = useState(true);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const scrollDirection = useScrollDirection();
  const [merchantIdx, setMerchantIdx] = useState(props.merchantIdx);
  // Loading in Button
  const [isLoading, setLoading] = useState(false);

  const setToggle = () => {
    if (screenSize < 987) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    setScreenSize(window.innerWidth);
    setToggle();
  }, [window.innerWidth]);

  useEffect(() => {
    setMerchantIdx(props.merchantIdx)
  }, [props.merchantIdx])

  // Send Request to Backend to Add the Token to the token_block_list Table
  function handleLogout() {
    setLoading(true);
    AxiosInstanceUserServer.delete('/account/logout').then((res) => {
      if (res.data === 'Token Expired') {
        // Delete the Token from Cookies
        removeCookie('jwt_token');
        // Redirect the User to the Same page
        // window.location.replace(`${window.location.href}`);
        window.location.replace(
          `${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}`
        );
      }
    });
  }

  function handleChange(e) {
    const merchantObject = props.tokenData.merchants[e];
    setMerchantIdx(e);
    window.location.replace(
      props.tokenData.owner.clearance_status === 'rejected'
        ? `${process.env.REACT_APP_FRONTEND_URL_USER}/rejected`
        : merchantObject.status === 'completed'
        ? `${process.env.REACT_APP_FRONTEND_URL_MENACART}/dashboard?id=${merchantObject.id}`
        : merchantObject.status === 'waiting_business_scheme' ||
          merchantObject.status === 'waiting_information'
        ? `${process.env.REACT_APP_FRONTEND_URL_ONBOARDING}/onboarding?id=${merchantObject.id}`
        : merchantObject.status === 'pending_verification'
        ? `${process.env.REACT_APP_FRONTEND_URL_ONBOARDING}/pending-verification?id=${merchantObject.id}`
        : merchantObject.status === 'new-merchant'
        ? `${process.env.REACT_APP_FRONTEND_URL_ONBOARDING}/new-merchant?id=${merchantObject.id}`
        : merchantObject.status === 'request_meeting'
        ? `${process.env.REACT_APP_FRONTEND_URL_ONBOARDING}/request-meeting?id=${merchantObject.id}`
        : merchantObject.status === 'blocked'
        ? `${process.env.REACT_APP_FRONTEND_URL_ONBOARDING}/blocked?id=${merchantObject.id}`
        : merchantObject.status === 'request_correction' ||
          merchantObject.status === 'waiting_tos_acceptance'
        ? `${process.env.REACT_APP_FRONTEND_URL_ONBOARDING}/request-correction?id=${merchantObject.id}`
        : merchantObject.status === 'rejected'
        ? `/rejected?id=${merchantObject.id}`
        : ''
    );
  }
  return (
    <Navbar
      className='nav'
      sticky='top'
      collapseOnSelect
      // onToggle={setToggle}
      expand='lg'
      // the navbar will take the style according to scroll direction
      style={
        scrollDirection === 'up'
          ? {
            padding:'0',
            margin:'0',
            position: 'sticky',
            top: '0',
            backgroundColor:
              screenSize > 987 ? 'rgba(248, 249, 253, 1)' : 'white',
            // boxShadow: "0 4px 6px -7px black",

            width: '100%',
            height: '100%',
            zIndex: '2',
            transitionProperty: 'all',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '500ms',
          }
          : {
            top: screenSize > 987 ? '-100px' : '0px',
            backgroundColor:
              screenSize > 987 ? 'rgba(248, 249, 253, 1)' : 'white',
            zIndex: '2',
          }
      }>
      <Container>
        {/* Fintesa Logo */}
        <Nav.Link
          href={`${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}`}
          style={{ textDecoration: 'none', width: '50%' }}
          onClick={() =>
            setPagePath(`${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}`)
          }>
          <Navbar.Brand
            style={{ fontSize: '40px', margin: 'auto', position: 'relative' }}>
            <Image
              alt='FintesaLogo'
              src={onboardingImages['FintesaIcon.png']}
              style={{ width: '50px', marginRight: '2%' }}></Image>
            Fintesa
            <p
            className='allInOne'
              style={{
                fontSize: '14px',
                position: 'absolute',
                left: '35%',
                top: '80%',
              }}>
              All-In-One Financials Solution!
            </p>
          </Navbar.Brand>
        </Nav.Link>

        {/* Toggle for the Small Screens */}
        <Row>
          <Navbar.Toggle onClick={() => setExpanded(false)} />
        </Row>

        {/* Main Pages */}
        <Navbar.Collapse style={{ justifyContent: 'start' }}>
          <Nav style={{ width: '100%', justifyContent: 'end' }}>
            {/* Home Page */}
            <a
              className='item'
              href={`${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}`}
              style={
                pagePath ===
                  `${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}`
                  ? {
                    borderBottom: 'solid blue',
                    textDecoration: 'none',
                    fontSize: '20px',
                    marginRight: '30px',
                    marginTop: '5px',
                    width: '125%',
                  }
                  : {
                    color: 'rgba(0,0,0,.55)',
                    textDecoration: 'none',
                    fontSize: '20px',
                    marginRight: '30px',
                    marginTop: '5px',
                  }
              }>
              Home
            </a>

            {/* Pricing Page */}
            <a
              className='item'
              href={`${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}/pricing`}
              style={
                pagePath ===
                  `${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}/pricing`
                  ? {
                    borderBottom: 'solid blue',
                    textDecoration: 'none',
                    fontSize: '20px',
                    marginRight: '30px',
                    marginTop: '5px',
                    width: '125%',
                  }
                  : {
                    color: 'rgba(0,0,0,.55)',
                    textDecoration: 'none',
                    fontSize: '20px',
                    marginRight: '30px',
                    marginTop: '5px',
                  }
              }>
              Pricing
            </a>

            {/* Login Page */}
            {props.tokenData.status !== 'token is valid' ? (
              <a
                className='item'
                href={`${process.env.REACT_APP_FRONTEND_URL_USER}/login`}
                style={
                  pagePath ===
                    `${process.env.REACT_APP_FRONTEND_URL_USER}/login`
                    ? {
                      borderBottom: 'solid blue',
                      textDecoration: 'none',
                      fontSize: '20px',
                      marginRight: '30px',
                      marginTop: '5px',
                      width: '125%',
                    }
                    : {
                      color: 'rgba(0,0,0,.55)',
                      textDecoration: 'none',
                      fontSize: '20px',
                      marginRight: '30px',
                      marginTop: '5px',
                    }
                }>
                Login
              </a>
            ) : (
              <>
                <Dropdown align='end'>
                  <Dropdown.Toggle
                    id='dropdown-button-dark-example1'
                    variant=''
                    style={{ marginTop: '10px' }}>
                    {props.tokenData.owner.first_legal_name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    variant=''
                    style={{
                      backgroundColor: 'rgba(248, 249, 253, 1)',
                      margin: '0',
                      padding: '0 0 0 0',
                    }}>
                    <Row
                      style={{
                        width: '290px',
                        margin: '0',
                        padding: '0 0 18px 0',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(248, 249, 253, 1)',
                      }}>
                      {/* Row For Owner Name */}
                      <Row
                        style={{
                          margin: '0',
                          padding: '18px 0 18px 0',
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: '#fff',
                          boxShadow: '0 1px 0px 0 rgb(0 0 0 / 20%)',
                        }}>
                        <p style={{ margin: '0', padding: '0 0 0 0 ' }}>
                          Welcome,{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {props.tokenData.owner.first_legal_name}!
                          </span>
                        </p>
                      </Row>
                      {/* Merchant with Product */}
                      <Row
                        style={{
                          margin: '0',
                          padding: '18px',
                          textAlign: 'center',
                        }}>
                        <Row
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '0',
                            padding: '0 0 0 0',
                          }}>
                          <Form.Select
                            size='sm'
                            value={merchantIdx}
                            // defaultValue={props.tokenData.merchants[0]}
                            style={{
                              color: '#008CBA',
                              borderRadius: '5px',
                              fontWeight: 'bold',
                            }}
                            onChange={(e) => handleChange(e.target.value)}
                          // onChange={(e) => setMerchantIdx(e.target.value)}
                          >
                            {/* <option value={'string'}>Choose an option</option> */}
                            {props.tokenData.merchants.map((merchant, idx) => (
                              <option value={idx} key={merchant.id}>
                                {merchant.display_name
                                  ? `${merchant.display_name}`
                                  : `Merchant ${idx + 1}`}
                              </option>
                            ))}
                          </Form.Select>

                          <Row
                            className='MC'
                            lg={4}
                            md={4}
                            style={{
                              width: '80%',
                              margin: '0',
                              marginTop: '15px',
                              padding: '0 0 0 0',
                            }}>
                            {/* MENACart */}

                            <Col>
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id='button-tooltip'>
                                    Fintesa App{' '}
                                  </Tooltip>
                                }>
                                <Button
                                  variant='info'
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '0',
                                  }}
                                  onClick={() => handleChange(merchantIdx)}>
                                  FA
                                </Button>
                              </OverlayTrigger>
                            </Col>

                            {/* Vbank */}
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id='button-tooltip'>VBank </Tooltip>
                              }>
                              <Col data-letters='VB'></Col>
                            </OverlayTrigger>

                            {/* Qassetni */}
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id='button-tooltip'>Qassetni </Tooltip>
                              }>
                              <Col data-letters='QT'></Col>
                            </OverlayTrigger>

                            {/* Vertify */}
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id='button-tooltip'>Vertify </Tooltip>
                              }>
                              <Col data-letters='VF'></Col>
                            </OverlayTrigger>
                          </Row>
                        </Row>
                      </Row>
                      {/* Row For Button */}
                      <Row
                        style={{
                          margin: '0',
                          padding: '0 0 0 0',
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        {/*  Account Information */}
                        <Row style={{ width: '90%', marginTop: '20px' }}>
                          <a
                            href={`/settings?id=${props.idQuery}`}
                            className='Header_link'>
                            <span className='Header_link_span'>
                              <BsPersonCircle
                                size='22'
                                style={{ marginRight: '12px' }}
                              />{' '}
                              Account Information
                            </span>
                          </a>
                        </Row>

                        {/* Subscription Management */}
                        <Row style={{ width: '90%', marginTop: '15px' }}>
                          <Button
                            variant='secondary'
                            disabled
                            style={{
                              boxShadow: '0 2px 4px rgb(50 50 93 / 10%)',
                              padding: '0',
                            }}>
                            <span className='Header_link_span'>
                              <MdOutlineManageAccounts
                                size='22'
                                style={{ marginRight: '12px' }}
                              />{' '}
                              Subscription Management
                            </span>
                          </Button>
                        </Row>

                        {/* Logout Button */}

                        <Row style={{ width: '90%', marginTop: '15px' }}>
                          <Button
                            variant='secondary'
                            id='logout-button'
                            style={{
                              boxShadow: '0 2px 4px rgb(50 50 93 / 10%)',
                            }}
                            onClick={!isLoading ? handleLogout : null}>
                            <FiLogIn
                              size='22'
                              style={{ marginRight: '12px' }}
                            />
                            {isLoading ? (
                              <>
                                Loading…{' '}
                                <Spinner
                                  animation='border'
                                  variant='light'
                                  size='sm'
                                />
                              </>
                            ) : (
                              'Logout'
                            )}
                          </Button>
                        </Row>
                      </Row>{' '}
                    </Row>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default React.memo(Header);
