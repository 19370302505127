/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import merchantDetailModalCardsStyle from '../../../styles/merchantDetailModalCardsStyle';
import CountrySelector from '../../CountrySelector';

import axios from 'axios';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from '../../PhoneNumberInput';

export default function MerchantContactInformationAndLocationDetails(props) {
  const [MerchantCity, setMerchantCity] = useState(props.cityMerchant);
  const [MerchantCountry, setMerchantCountry] = useState({
    value: '',
    label: '',
  });
  const [MerchantCitiesOptions, setMerchantCitiesOptions] = useState([
    { name: 'Amman' },
    { name: 'Irbid' },
    { name: "Az Zarqa'" },
    { name: 'Al `Aqabah' },
    { name: 'As Salt' },
    { name: 'Al Mafraq' },
    { name: 'Jarash' },
    { name: 'Ma`An' },
    { name: 'At Tafilah' },
    { name: 'Al Karak' },
    { name: 'Madaba' },
    { name: '`Ajlun' },
    { name: 'At Turrah' },
    { name: 'Sakib' },
  ]);

  // GET the Country Label Full Name by Giving the ISO2 Name
  function getCountryLabel(value, form) {
    const options = {
      method: 'GET',
      url: `https://api.api-ninjas.com/v1/country?name=${value}`,
      headers: {
        'X-Api-Key': 'nqgrQCdEBbU2l/InoSigOg==TuBqoXYfGeLWcVnb',
      },
    };
    axios.request(options).then((res) => {
      if (form === 1) {
        res.data[0].name === 'Palestine, State Of'
          ? setMerchantCountry({
              value: value,
              label: 'Palestine, not Israel',
            })
          : setMerchantCountry({
              value: value,
              label: res.data[0].name,
            });
      }
    });
  }

  useEffect(() => {
    getCountryLabel(props.countryMerchant, 1);
    MerchantCountryChange(props.countryMerchant);
    setMerchantCountry(props.countryMerchant);
  }, []);

  useEffect(() => {
    props.setCountryMerchant(MerchantCountry.value);
  }, [MerchantCountry]);

  // Change the Cities Options for the Merchant City Based on the Selected Country by Sending a request to Ninja API
  function MerchantCountryChange(givingCountry) {
    if (givingCountry === 'PS') {
      setMerchantCitiesOptions([
        { name: 'Jerusalem' },
        { name: 'Bethlehem' },
        { name: 'Beit Jala' },
        { name: 'Beit Sahour' },
        { name: 'Hebron' },
        { name: 'Sabastia' },
        { name: 'Jericho' },
        { name: 'Ramallah & El-Bireh' },
        { name: 'Nablus' },
        { name: 'Tulkarem' },
        { name: 'Jenin' },
        { name: 'Gaza' },
        { name: 'Rafah' },
        { name: 'Khan Younis' },
        { name: 'Tel Aviv-Yafo' },
        { name: 'Haifa' },
        { name: 'Rishon Leziyyon' },
        { name: 'Petah Tiqwa' },
        { name: 'Ashdod' },
        { name: 'Netanya' },
        { name: 'Beersheba' },
        { name: 'Bene Beraq' },
        { name: 'Holon' },
        { name: 'Ramat Gan' },
        { name: 'Bat Yam' },
        { name: 'Ashqelon' },
        { name: 'Rehovot' },
        { name: 'Bet Shemesh' },
        { name: 'Kefar Sava' },
        { name: 'Herzliyya' },
        { name: 'Nazareth' },
        { name: 'Ra`Ananna' },
        { name: 'Ramla' },
        { name: 'Giv`Atayim' },
        { name: 'Hod Hasharon' },
        { name: 'Qiryat Ata' },
        { name: 'Rosh Ha`Ayin' },
        { name: 'Umm El Fahm' },
        { name: 'Nes Ziyyona' },
        { name: 'Ramat Hasharon' },
        { name: "Karmi'el" },
        { name: 'Qiryat Ono' },
        { name: 'Qiryat Bialik' },
      ]);
      setMerchantCity('Jerusalem');
      getCountryLabel(givingCountry, 1);
    } else {
      const options = {
        method: 'GET',
        url: `https://api.api-ninjas.com/v1/city?country=${
          givingCountry.value ? givingCountry.value : givingCountry
        }&limit=1000`,
        headers: {
          'X-Api-Key': 'nqgrQCdEBbU2l/InoSigOg==TuBqoXYfGeLWcVnb',
        },
      };
      axios.request(options).then((res) => {
        setMerchantCitiesOptions(res.data);
        // Set the City State Value Based on the New Selected City
        setMerchantCity(
          res.data[
            MerchantCitiesOptions.findIndex(
              (option) => option.name === MerchantCity
            ) > res.data.length
              ? 0
              : MerchantCitiesOptions.findIndex(
                  (option) => option.name === MerchantCity
                )
          ].name
        );
        getCountryLabel(givingCountry, 1);
      });
    }
  }

  return (
    <Form>
      <Row style={merchantDetailModalCardsStyle.mainSectionStyle}>
        <Col lg={12}>
          <h4 style={merchantDetailModalCardsStyle.h4HeaderTitleSection}>
            Contact information and location
          </h4>
          <hr />
        </Col>

        <Row>
          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='support_email'
                    value='merchant-support_email,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-support_email'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Support email
                </p>
              </Col>

              <Col>
                <Form.Control
                  defaultValue={props.supportEmailMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setSupportEmailMerchant(e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='phone_number'
                    value='merchant-phone_number,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-phone_number'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Phone number
                </p>
              </Col>
              <Col>
                {!props.infoEditStatus ? (
                  <PhoneNumberInput
                    phoneNumber={props.phoneNumberMerchant}
                    setPhoneNumber={props.setPhoneNumberMerchant}
                    required
                  />
                ) : (
                  <Form.Control
                    defaultValue={props.phoneNumberMerchant}
                    disabled={props.infoEditStatus}
                    onChange={(e) =>
                      props.setPhoneNumberMerchant(e.target.value)
                    }
                  />
                )}
              </Col>
            </Row>

            <Row>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='country'
                    value='merchant-country,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-country'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>Country</p>
              </Col>
              <Col>
                <Form.Floating className='mb-3'>
                  <CountrySelector
                    disabled2={props.infoEditStatus}
                    country={MerchantCountry}
                    setCountry={setMerchantCountry}
                    countryChange={MerchantCountryChange}
                  />
                </Form.Floating>
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='city'
                    value='merchant-city,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-city'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>City</p>
              </Col>
              <Col>
                <Form.Select
                  defaultValue={props.cityMerchant}
                  disabled={props.infoEditStatus}
                  className='mb-3'
                  aria-label='city'
                  onChange={(e) => props.setCityMerchant(e.target.value)}>
                  {MerchantCitiesOptions.map((item) => (
                    <option
                      selected={MerchantCity === item.name ? true : false}
                      value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Col>

          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='line1'
                    value='merchant-line1,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-line1'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>line1</p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.line1Merchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) => props.setLine1Merchant(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='line2'
                    value='merchant-line2,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-line2'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>line2</p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.line2Merchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) => props.setLine2Merchant(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='postal_code'
                    value='merchant-postal_code,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-postal_code'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Postal code
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.postalCodeMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) => props.setPostalCodeMerchant(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Col></Col>
      </Row>
    </Form>
  );
}
