/** @format */

import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import merchantDetailModalCardsStyle from '../../../styles/merchantDetailModalCardsStyle';

export default function MerchantInformation(props) {
  return (
    <Form>
      <Row style={merchantDetailModalCardsStyle.mainSectionStyle}>
        <Col lg={12}>
          <h4 style={merchantDetailModalCardsStyle.h4HeaderTitleSection}>
            Merchant information
          </h4>
          <hr />
        </Col>
        <Row>
          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Merchant ID
                </p>
              </Col>
              <Col>
                <Form.Control defaultValue={props.merchantID} disabled={true} />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Referral
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.referralMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) => props.setReferralMerchant(e.target.value)}
                />
              </Col>
            </Row>
          </Col>

          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>Created</p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.createdMerchant}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>Updated</p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.updatedMerchant}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='website_link'
                    value='merchant-website_link,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-website_link'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}

                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Website link
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.websiteLink}
                  disabled={props.infoEditStatus}
                  onChange={(e) => props.setWebsiteLink(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}
