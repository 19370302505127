import React from 'react';
import { Row } from 'react-bootstrap';

import RequestCorrectionForm from '../components/forms/RequestCorrectionForm';
import RequestCorrectionImages from '../components/forms/RequestCorrectionImages';
export default function RequestCorrection(props) {

  return (
    <>
    <Row
      style={{
        margin: '0',
        padding: '0 0 0 0',
        justifyContent: 'center',
        display: 'flex',
      }}>
      <RequestCorrectionForm
        tokenData={props.tokenData}
        selectedMerchantData={props.selectedMerchantData}
      />
      {/* <RequestCorrectionImages
        tokenData={props.tokenData}
        selectedMerchantData={props.selectedMerchantData}
      /> */}


    </Row>
    </>
  );
}
