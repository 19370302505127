/** @format */

import React, { useState, useRef } from 'react';
import { Form, Button, Modal, Row, Spinner } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

import AxiosInstanceUserServer from '../axios/AxiosInstanceUserServer';

function TOSAcceptanceModal(props) {
  const listInnerRef = useRef();
  const [checked, setChecked] = useState(false);
  const [stripeChecked, setStripeChecked] = useState(false);
  const [reachedBottom, setReachBottom] = useState(true);
  const [reCaptchaValue, setReCaptchaValue] = useState(false);

  // https://thewebdev.info/2021/09/25/how-to-detect-when-a-user-scrolls-to-bottom-of-div-with-react/
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setReachBottom(true);
      }
    }
  };


  const handleSubmit = async () => {
    props.setLoading(true);

    await props.updateRequest();
  };

  return (
    <>
      <Modal
        backdrop='static'
        keyboard={false}
        show={props.tosModalShow}
        onHide={() => props.setTosModalShow(!props.tosModalShow)}
        size='lg'
        // backdrop='static'
      >
        <Modal.Header closeButton >
          <Modal.Title >Privacy Policies Requirements</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{ height: '200px', overflowY: 'auto' }}
            onScroll={onScroll}
            ref={listInnerRef}>
            <ul>
              <li>
                You are not allowed to sell for multiple businesses in the same
                account, if so, you might face fines that goes up to $100,000
                and/or legal enforcement laws.
              </li>
              <br />
              <li>
                If you want to sell from multi-businesses you should have multi
                merchants under your name.
              </li>
              <br />
              <li>
                You are fully responsible for legal and financial operations
                under your business/es. Be aware, if and only if you provide
                business service under any Prohibited Businesses. or any{' '}
                <a
                  style={{ textDecoration: 'none' }}
                  href='https://fintesa.co/prohibited-business-types'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Prohibited Businesses
                </a>{' '}
                illegal activity on your behalf through our services ("Fintesa" or it's related
                product/s) your account will be restricted without any notice.
              </li>
              <br />
              <li>
                Subscription/s are paid up-front, as long as the service/s is
                provided there is no refund/s, if your account were delayed for
                any reason/s before requesting meeting and after confirming that
                we need to verify your application/s, the days of the onboarding
                process will be credited to you until correction of the current
                situation which is at most 3 business days.
              </li>
              <br />
              <li>
                Tax compliance responsibilities fall upon the merchant, bearing
                all responsibilities.
              </li>
            </ul>
          </div>
        </Modal.Body>

        <Modal.Footer style={{ height: '70%' }}>
          <Row>
            <Form.Floating className='mb-3' style={{}}>
              <Form.Check
                onChange={() => setChecked(!checked)}
                disabled={!reachedBottom || props.isLoading}
                label='I agree to the upper terms and conditions and the privacy policy.'
                style={{ width: '150%' }}
              />
            </Form.Floating>
            <Form.Floating className='mb-3' style={{}}>
              <Form.Check
                onChange={() => setStripeChecked(!stripeChecked)}
                disabled={!reachedBottom || props.isLoading}
                label={
                  <p>
                    I agree to the{' '}
                    <a
                      style={{ textDecoration: 'none' }}
                      href='https://fintesa.co/terms-and-conditions'
                      target='_blank'
                      rel='noopener noreferrer'>
                      Fintesa terms
                    </a>{' '}
                    and conditions and the privacy.
                  </p>
                }
                style={{ width: '150%' }}
              />
            </Form.Floating>

            {/* ReCAPTCHA */}
            <ReCAPTCHA
              sitekey='6Lfs8HUhAAAAAHr_IIGzY9YeuIwdYvGO9IL3mfgY'
              onChange={() => setReCaptchaValue(!reCaptchaValue)}
              style={{ marginRight: '60%' }}
            />
          </Row>

          <Button
            disabled={
              reCaptchaValue && checked && stripeChecked && !props.isLoading
                ? false
                : true
            }
            onClick={!props.isLoading ? handleSubmit : null}
            style={
              reCaptchaValue && checked && stripeChecked
                ? {
                    marginTop: '20px',
                    marginBottom: '20px',
                  }
                : {
                    border: '1px solid #999999',
                    backgroundColor: '#cccccc',
                    color: '#666666',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }
            }>
            {props.isLoading ? (
              <span>
                Processing...{'      '}
                <Spinner animation='border' variant='light' size='sm' />
              </span>
            ) : (
              'Submit'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default TOSAcceptanceModal;
