/** @format */

import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import merchantDetailModalCardsStyle from '../../../styles/merchantDetailModalCardsStyle';

export default function MerchantSubscriptionDetails(props) {
  return (
    <Form>
      <Row style={merchantDetailModalCardsStyle.mainSectionStyle}>
        <Col lg={12}>
          <h4 style={merchantDetailModalCardsStyle.h4HeaderTitleSection}>
            Merchant Subscription details
          </h4>
          <hr />
        </Col>

        <Row>
          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Subscription id
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.subscriptionIDMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setSubscriptionIDMerchant(e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='subscription_plan'
                    value='merchant-subscription_plan,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-subscription_plan'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}

                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Subscription plan
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.subscriptionPlanMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setSubscriptionPlanMerchant(e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Accounts limit
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.accountsLimit}
                  disabled={props.infoEditStatus}
                  onChange={(e) => props.setAccountsLimit(e.target.value)}
                />
              </Col>
            </Row>
          </Col>

          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Fees Percentage
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.feesPercentageMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setFeesPercentageMerchant(e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Transaction fee
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.transactionFeeMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setTransactionFeeMerchant(e.target.value)
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}
