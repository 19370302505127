import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import PhoneNumberInput from "../components/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CountrySelector from "../components/CountrySelector";
import axios from "axios";
import TOSAcceptanceModal from "../components/modals/TOSAcceptanceModal";
import AxiosInstanceUserServer from "../components/axios/AxiosInstanceUserServer";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";

function NewMerchant(props) {
  const [tosModalShow, setTosModalShow] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState({
    value: "JO",
    label: "Jordan",
  });
  const [city, setCity] = useState("Amman");
  const [businessType, setBusinessType] = useState("individual");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [legalName, setLegalName] = useState("");
  const [companyStructure, setCompanyStructure] = useState(
    "Sole Proprietorship"
  );
  const [websiteLink, setWebsiteLink] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [
    imageUploadRegistrationCertificate,
    setImageUploadRegistrationCertificate,
  ] = useState(null);

  const [merchantCitiesOptions, setMerchantCitiesOptions] = useState([
    { name: "Amman" },
    { name: "Irbid" },
    { name: "Az Zarqa'" },
    { name: "Al `Aqabah" },
    { name: "As Salt" },
    { name: "Al Mafraq" },
    { name: "Jarash" },
    { name: "Ma`An" },
    { name: "At Tafilah" },
    { name: "Al Karak" },
    { name: "Madaba" },
    { name: "`Ajlun" },
    { name: "At Turrah" },
    { name: "Sakib" },
  ]);

  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // Reset States Values when the business type changes
  useEffect(() => {
    setCountry({
      value: "JO",
      label: "Jordan",
    });
    setCity("Amman");
    setAddressLine1("");
    setAddressLine2("");
    setPostalCode("");
    setLegalName("");
    setRegistrationNumber("");
    setWebsiteLink("");
  }, [businessType])

  const handleCreate = (e) => {
    e.preventDefault();
    // Check if the Form is Valid
    const form = e.currentTarget;
    var reg = /^[a-zA-Z0-9._ -]+$/;

    // The regex only allow 3 to 22 characters, the last character cannot be a space, and must inlude at least one alphabetical character
    // Allowed special characters are-->  $@$!%*?&#-_. +
    var regDisplayName =
      /^(?=.*[a-zA-Z])(?!.*\s$)(?=.{5,22}$)[a-zA-Z0-9$@$!%?&#_. +\u0600-\u06FF]+$/;
    if (form.checkValidity()) {
      if (businessType === 'company' && !imageUploadRegistrationCertificate) {
        toast.error("please Upload Your Registration Certificate");
        return;
      }
      if (!regDisplayName.test(displayName)) {
        toast.error(`Please enter the display name without / or \\`);
        return;
      }
      if (!isValidPhoneNumber(phoneNumber)) {
        toast.error(`Invalid Phone Number it Should be Like +XXX XX XXXX XXXX`);
        return;
      }

      // to upload Registration Image
      uploadRegistrationImage();
      setLoading(false);
      setValidated(true);

      let phone_number = phoneNumber.slice(1); // Delete the First Two Characters '+ '

      const merchant = {
        business_type: businessType,
        display_name: displayName,
        legal_name: legalName,
        registration_number:
          registrationNumber === "" ? null : registrationNumber,
        company_structure: businessType === "company" ? companyStructure : null,
        support_email: supportEmail,
        phone_number: phoneNumber === "" ? null : phone_number,
        website_link: websiteLink === "" ? null : websiteLink,
        country: businessType === "company" ? country.value : (props.tokenData.owner.country_of_residence ? props.tokenData.owner.country_of_residence : props.tokenData.owner.country),
        city: businessType === "company" ? city : props.tokenData.owner.city,
        line1: addressLine1,
        line2: addressLine2,
        postal_code: postalCode === "" ? null : postalCode,
        statement_descriptor: displayName,
      };
      setTosModalShow(true);
    } else {
      e.stopPropagation();
    }
    setValidated(true);
  };
  const updateRequest = async () => {
    let phone_number = phoneNumber.slice(1); // Delete the First Two Characters '+ '
    await AxiosInstanceUserServer.put(
      `/merchant/update/${props.selectedMerchantData.id}`,
      {
        business_type: businessType,
        display_name: displayName,
        legal_name: legalName,
        registration_number:
          registrationNumber === "" ? null : registrationNumber,
        company_structure: businessType === "company" ? companyStructure : null,
        support_email: supportEmail,
        phone_number: phoneNumber === "" ? null : phone_number,
        website_link: websiteLink === "" ? null : websiteLink,
        country: businessType === "company" ? country.value : (props.tokenData.owner.country_of_residence ? props.tokenData.owner.country_of_residence : props.tokenData.owner.country),
        city: businessType === "company" ? city : props.tokenData.owner.city,
        line1: addressLine1,
        line2: addressLine2,
        postal_code: postalCode === "" ? null : postalCode,
        statement_descriptor: displayName,
      }
    ).catch(() => {
      toast.error("Something Went Wrong, Please Try Again");
      props.setLoading(false);
    });
  };
  const uploadRegistrationImage = (e) => {
    if (imageUploadRegistrationCertificate == null) return;
    const isJpgOrPng =
      imageUploadRegistrationCertificate.type === "image/jpeg" ||
      imageUploadRegistrationCertificate.type === "image/jpg" ||
      imageUploadRegistrationCertificate.type === "application/pdf" ||
      imageUploadRegistrationCertificate.type === "image/png";

    if (!isJpgOrPng) {
      toast.error(
        `You can only upload JPG, JPEG, PNG, or PDF file! ${imageUploadRegistrationCertificate.type}`
      );
      return;
    }

    // change name of folder from here
    const imageRefRegistrationCertificate = ref(
      storage,
      `${props.tokenData.owner.id}/${displayName}/RegistrationCertificate`
    );
    uploadBytes(
      imageRefRegistrationCertificate,
      imageUploadRegistrationCertificate
    )
      .then(() => {})
      .catch(() => {
        toast.error(
          'Something Went Wrong When Uploading Your Merchant Registration Certificate, Please Try Again!'
        );
      });
  };
  // Change the Cities Options for the Merchant City Based on the Selected Country by Sending a request to Ninja API
  function merchantCountryChange(givingCountry) {
    if (givingCountry === "PS") {
      setMerchantCitiesOptions([
        { name: "Jerusalem" },
        { name: "Bethlehem" },
        { name: "Beit Jala" },
        { name: "Beit Sahour" },
        { name: "Hebron" },
        { name: "Sabastia" },
        { name: "Jericho" },
        { name: "Ramallah & El-Bireh" },
        { name: "Nablus" },
        { name: "Tulkarem" },
        { name: "Jenin" },
        { name: "Gaza" },
        { name: "Rafah" },
        { name: "Khan Younis" },
        { name: "Tel Aviv-Yafo" },
        { name: "Haifa" },
        { name: "Rishon Leziyyon" },
        { name: "Petah Tiqwa" },
        { name: "Ashdod" },
        { name: "Netanya" },
        { name: "Beersheba" },
        { name: "Bene Beraq" },
        { name: "Holon" },
        { name: "Ramat Gan" },
        { name: "Bat Yam" },
        { name: "Ashqelon" },
        { name: "Rehovot" },
        { name: "Bet Shemesh" },
        { name: "Kefar Sava" },
        { name: "Herzliyya" },
        { name: "Nazareth" },
        { name: "Ra`Ananna" },
        { name: "Ramla" },
        { name: "Giv`Atayim" },
        { name: "Hod Hasharon" },
        { name: "Qiryat Ata" },
        { name: "Rosh Ha`Ayin" },
        { name: "Umm El Fahm" },
        { name: "Nes Ziyyona" },
        { name: "Ramat Hasharon" },
        { name: "Karmi'el" },
        { name: "Qiryat Ono" },
        { name: "Qiryat Bialik" },
      ]);
      setCity("Jerusalem");
      getCountryLabel(givingCountry);
    } else {
      const options = {
        method: "GET",
        url: `https://api.api-ninjas.com/v1/city?country=${
          givingCountry.value ? givingCountry.value : givingCountry
        }&limit=1000`,
        headers: {
          "X-Api-Key": "nqgrQCdEBbU2l/InoSigOg==TuBqoXYfGeLWcVnb",
        },
      };
      axios.request(options).then((res) => {
        setMerchantCitiesOptions(res.data);
        // Set the City State Value Based on the New Selected City
        setCity(res.data[0].name);
        getCountryLabel(givingCountry);
      });
    }
  }
  // GET the Country Label Full Name by Giving the ISO2 Name
  function getCountryLabel(value) {
    const options = {
      method: "GET",
      url: `https://api.api-ninjas.com/v1/country?name=${value}`,
      headers: {
        "X-Api-Key": "nqgrQCdEBbU2l/InoSigOg==TuBqoXYfGeLWcVnb",
      },
    };
    axios.request(options).then((res) => {
      res.data[0].name === "Palestine, State Of"
        ? setCountry({
            value: value,
            label: "Palestine, not Israel",
          })
        : setCountry({
            value: value,
            label: res.data[0].name,
          });
    });
  }

  return (
    <div style={{ width: "clamp(280px, 500px, 90%)", margin: "auto" }}>
      {tosModalShow && (
        <TOSAcceptanceModal
          selectedMerchantData={props.selectedMerchantData}
          tosModalShow={tosModalShow}
          setTosModalShow={setTosModalShow}
          updateRequest={updateRequest}
          setLoading={setLoading}
          isLoading={isLoading}
        />
      )}
      <Form noValidate validated={validated} onSubmit={handleCreate}>
        {/* Merchant */}
        <Row style={{ justifyContent: "center" }}>
          {/* Business Type */}
          <Form.Floating>
            <Form.Select
              className="mb-3"
              aria-label="business_type"
              onChange={(e) => setBusinessType(e.target.value)}
              defaultValue={
                props.selectedMerchantData.business_type === "company"
                  ? "company"
                  : "individual"
              }
            >
              <option value="individual">Individual</option>
              <option value="company">Company</option>
            </Form.Select>
            <label> Select Business Type </label>
          </Form.Floating>
          {/* Display Name */}
          <Form.Floating className="mb-3 mt-3">
            <Form.Control
              type="text"
              name="display_name"
              value={displayName}
              placeholder="Display Name"
              onChange={(e) => setDisplayName(e.target.value)}
              required
            />
            <Form.Label>Display Name</Form.Label>
          </Form.Floating>
          {/* Support Email */}
          <Form.Floating className="mb-3">
            <Form.Control
              type="email"
              name="support_email"
              placeholder="Email"
              value={supportEmail}
              onChange={(e) => setSupportEmail(e.target.value)}
              required
            />
            <Form.Label>Support Email</Form.Label>
          </Form.Floating>
          {/* Merchant Phone Number */}
          <Form.Group controlId="phoneNumber" className="mb-3">
            <Form.Label>Support Phone</Form.Label>
            <PhoneNumberInput
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </Form.Group>
          {businessType === "company" && (
            <>
              {/* Merchant Country */}
              <Form.Label>Country</Form.Label>
              <Form.Floating className="mb-3">
                <Form.Label>Country</Form.Label>
                <CountrySelector
                  country={country}
                  setCountry={setCountry}
                  countryChange={merchantCountryChange}
                />
              </Form.Floating>
              {/* npm install countries-and-cities-api  */}{" "}
              {/* if you want to use select city depends on the Country */}
              {/* Merchant City */}
              <Form.Group controlId="city" className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Select
                  className="mb-3"
                  aria-label="city"
                  onChange={(e) => setCity(e.target.value)}
                >
                  {merchantCitiesOptions.map((item, idx) => (
                    <option key={idx} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {/* Merchant Address Line1 */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Address Line1"
                  name="line1"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
                <Form.Label>Address Line1</Form.Label>
              </Form.Floating>
              {/* Merchant Address Line2 */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Address Line2"
                  name="line2"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
                <Form.Label>Address Line2</Form.Label>
              </Form.Floating>
              {/* Merchant Postal Code */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Postal Code"
                  name="postal_code"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
                <Form.Label>Postal Code</Form.Label>
              </Form.Floating>
              {/* --- Second Column --- */}
              {/* Legal Name */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Legal Name"
                  name="legal_name"
                  value={legalName}
                  onChange={(e) => setLegalName(e.target.value)}
                />
                <Form.Label>Legal Name</Form.Label>
              </Form.Floating>
              {/* registration certificate */}
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Registration Certificate</Form.Label>
                <Form.Control
                  size="sm"
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf"
                  name="registration_certificate"
                  placeholder="registration_certificate"
                  onChange={(e) => {
                    setImageUploadRegistrationCertificate(e.target.files[0]);
                  }}
                  required
                ></Form.Control>
              </Form.Group>
              {/* Registration Number */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="registration_number"
                  placeholder="Registration Number"
                  value={registrationNumber}
                  onChange={(e) => setRegistrationNumber(e.target.value)}
                  required
                />
                <Form.Label>Registration Number</Form.Label>
              </Form.Floating>
              {/* Company Structure */}
              <Form.Floating className="mb-3">
                <Form.Select
                  aria-label="company_structure"
                  value={companyStructure}
                  onChange={(e) => setCompanyStructure(e.target.value)}
                >
                  {/* Sole Proprietorship */}
                  <option value="Sole Proprietorship">
                    Sole Proprietorship
                  </option>
                  {/* LLC */}
                  <option value="LLC">LLC</option>
                  {/* Corporation */}
                  <option value="Corporation">Corporation</option>
                  {/* Partnership */}
                  <option value="Partnership">Partnership</option>
                </Form.Select>
                <label>Company Structure</label>
              </Form.Floating>
              {/* Merchant Website Link */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="url"
                  name="website_link"
                  placeholder="Website Link"
                  value={websiteLink}
                  onChange={(e) => setWebsiteLink(e.target.value)}
                />
                <Form.Label>Wepsite Link</Form.Label>
              </Form.Floating>
            </>
          )}
          {/* Submit Button */}
          <Row style={{ margin: "0" }}>
            <Button
              className="Loginbutton Lbutton"
              // onClick={handleSubmit}
              type="submit"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              Submit
            </Button>
          </Row>
        </Row>
      </Form>
    </div>
  );
}

export default NewMerchant;
