/** @format */

import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import merchantDetailModalCardsStyle from '../../../styles/merchantDetailModalCardsStyle';

export default function MerchantBusinessDetails(props) {
  return (
    <Form>
      <Row style={merchantDetailModalCardsStyle.mainSectionStyle}>
        <Col lg={12}>
          <h4 style={merchantDetailModalCardsStyle.h4HeaderTitleSection}>
            Merchant Business details
          </h4>
          <hr />
        </Col>

        <Row>
          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='business_scheme'
                    value='merchant-business_scheme,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-business_scheme'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Business scheme
                </p>
              </Col>
              <Col>
                <Row>
                  <Form.Select
                    value={props.businessScheme}
                    disabled={props.infoEditStatus}
                    size='sm'
                    style={{ width: '95%', marginLeft: '10px' }}
                    onChange={(e) => props.setBusinessScheme(e.target.value)}>
                    <option value={'Products and Services'}>
                      Products and Services
                    </option>
                    <option value={'Subscriptions'}>Subscriptions</option>
                    <option value={'All Schemes'}>All Schemes</option>
                  </Form.Select>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='business_type'
                    value='merchant-business_type,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-business_type'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Business type
                </p>
              </Col>
              <Col>
                <Row>
                  <Form.Select
                    value={props.businessType}
                    disabled={props.infoEditStatus}
                    size='sm'
                    style={{ width: '95%', marginLeft: '10px' }}
                    onChange={(e) => props.setBusinessType(e.target.value)}>
                    <option value={'individual'}>individual</option>
                    <option value={'company'}>company</option>
                  </Form.Select>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='display_name'
                    value='merchant-display_name,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-display_name'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Display name
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.displayNameMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) => props.setDisplayNameMerchant(e.target.value)}
                />
              </Col>
            </Row>
          </Col>

          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='legal_name'
                    value='merchant-legal_name,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-legal_name'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Legal name
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.legalNameMerchant}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='registration_number'
                    value='merchant-registration_number,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-registration_number'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Registration number
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.registrationNumberMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setRegistrationNumberMerchant(e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='company_structure'
                    value='merchant-company_structure,'
                    checked={props.statusCorrectionFields.includes(
                      'merchant-company_structure'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Company structure
                </p>
              </Col>
              <Col>
                <Form.Select
                  value={props.companyStructure}
                  disabled={props.infoEditStatus}
                  size='sm'
                  style={{ width: '95%' }}
                  onChange={(e) => props.setCompanyStructure(e.target.value)}>
                  <option value={'Sole Proprietorship'}>
                    Sole Proprietorship
                  </option>
                  <option value={'LLC'}>LLC</option>
                  <option value={'Corporation'}>Corporation</option>
                  <option value={'Partnership'}>Partnership</option>
                </Form.Select>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}
