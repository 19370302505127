/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

function CountrySelector(props) {
  const options = useMemo(() => countryList().getData(), []);
  useEffect(() => {
    options.splice(109, 1);
    options[169] = { value: 'PS', label: 'Palestine, not Israel' };
  }, []);

  const changeHandler = async (country) => {
    await props.setCountry(country);
    if(props.countryChange) {
      props.countryChange(country.value);
    }
  };

  return (
    <Select options={options} value={props.country} onChange={changeHandler} />
  );
}
export default React.memo(CountrySelector);
