/** @format */

import React, { useState } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import CountrySelector from '../../CountrySelector';
import merchantDetailModalCardsStyle from '../../../styles/merchantDetailModalCardsStyle';

export default function VerificationDetails(props) {
  return (
    <Form>
      <Row style={merchantDetailModalCardsStyle.mainSectionStyle}>
        <Col lg={12}>
          <h4 style={merchantDetailModalCardsStyle.h4HeaderTitleSection}>
            Verification details
          </h4>
          <hr />
        </Col>
        <Row>
          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Verification national ID
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.verificationNationalId}
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setVerificationNationalId(e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Verification city
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.verificationCity}
                  disabled={props.infoEditStatus}
                  onChange={(e) => props.setVerificationCity(e.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Clearance status
                </p>
              </Col>
              <Col>
                <Form.Select
                  defaultValue={props.clearanceStatus}
                  disabled={props.infoEditStatus}
                  size='sm'
                  style={{ width: '95%', marginLeft: '10px' }}
                  onChange={(e) => props.setClearanceStatus(e.target.value)}>
                  <option value={'not_started'}>Not Started</option>
                  <option value={'in_progress'}>In Progress</option>
                  <option value={'completed'}>Completed</option>
                  <option value={'need_extra_check'}>Need Extra Check</option>
                  <option value={'rejected'}>Rejected</option>
                </Form.Select>
              </Col>
            </Row>
          </Col>

          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Verification expire date
                </p>
              </Col>
              <Col>
                <Form.Control
                  type={props.infoEditStatus ? 'text' : 'date'}
                  defaultValue={
                    props.infoEditStatus && `${props.verificationExpireDate}`
                  }
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setVerificationExpireDate(e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  verification country
                </p>
              </Col>
              <Col>
                <Form.Floating className='mb-3'>
                  <CountrySelector
                    disabled2={props.infoEditStatus}
                    country={props.verificationCountry}
                    setCountry={props.setVerificationCountry}
                    // countryChange={ownerCountryChange}
                  />
                </Form.Floating>
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Clearance status description
                </p>
              </Col>
              <Col>
                <Form.Control
                  as='textarea'
                  defaultValue={props.clearanceStatusDescription}
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setClearanceStatusDescription(e.target.value)
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}
