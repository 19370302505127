/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';

import OnBoardingListTable from '../components/tables/OnBoardingListTable';
import AxiosInstanceOnboardingServer from '../components/axios/AxiosInstanceOnboardingServer';

export default function OnBoardingTeam(props) {
  const [allData, setAllData] = useState([]);

  function getAllOwner() {
    AxiosInstanceOnboardingServer.get('/onboarding-team/accounts/list').then(
      (res) => {
        if (res.data) {
          setAllData(res.data);
        }
      }
    );
  }
  useEffect(() => {
    getAllOwner();
  }, []);

  function EmailListCompare() {
    var StringListEmail = `${process.env.REACT_APP_EMAIL_LIST}`;
    var ArrayListEmail = StringListEmail.split(',');
    if (window.location.pathname === `/onboarding-team`) {
      if (!ArrayListEmail.includes(props.tokenData.owner.email)) {
        window.location.replace('/');
      }
    }
  }
  useEffect(() => {
    if (props.tokenData.owner) {
      EmailListCompare();
    }
  }, [props.tokenData]);

  return (
    <>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0',
          padding: '0 0 0 0',
        }}>
        {/* <>Add Filter Here</> */}
        <>
          {allData.length === 0 ? (
            <Row
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '100px',
              }}>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                }}>
                <p style={{ fontWeight: 'bold', fontSize: '24px' }}>
                  No Owner found for applied filters
                </p>
              </Row>
            </Row>
          ) : (
            <Row
              style={{
                width: '98%',
                display: 'flex',
                justifyContent: 'center',
                margin: '0',
                padding: '0 0 0 0',
              }}>
              <OnBoardingListTable allData={allData} />
            </Row>
          )}
        </>
      </Row>
    </>
  );
}
