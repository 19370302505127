/** @format */

import { Row, Col, Form } from 'react-bootstrap';

import merchantDetailModalCardsStyle from '../../../styles/merchantDetailModalCardsStyle';

export default function AccountInformation(props) {
  return (
    <Form>
      <Row style={merchantDetailModalCardsStyle.mainSectionStyle}>
        <Col lg={12}>
          <h4 style={merchantDetailModalCardsStyle.h4HeaderTitleSection}>
            Account information
          </h4>
          <hr />
        </Col>

        <Row>
          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Onboarding Attempts
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.onboardingAttempts}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>ID</p>
              </Col>
              <Col>
                <Form.Control defaultValue={props.ownerID} disabled={true} />
              </Col>
            </Row>
          </Col>

          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>Created</p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.ownerCreated}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>Updated</p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.ownerUpdated}
                  disabled={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}
