/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import { Row } from 'react-bootstrap';
import { InlineWidget } from 'react-calendly';

export default function RequestMeetingPage(props) {
  return (
    <>
      <Row
        style={{
          width: '100%',
          margin: '0',
          textAlign: 'center',
          padding: '0',
        }}>
        <h3
          style={{
            marginTop: '100px',
            marginBottom: '50px',
            fontWeight: 'bold',
          }}>
          We still need to verify some information inside your application,
          please choose an appointment for an online meeting.
        </h3>
        <InlineWidget
          url='https://calendly.com/menacart-demo/30min'
          styles={{ height: '650px', marginBottom: '100px' }}
        />
      </Row>
    </>
  );
}
