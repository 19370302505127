/** @format */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import AxiosInstanceUserServer from '../axios/AxiosInstanceUserServer';

function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 15000));
}

function SchemesListModal(props) {
  // Loading in Button
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  // Send Request to Update the Selected Business Scheme
  async function update_scheme(scheme) {
    setLoading(true);
    await AxiosInstanceUserServer.put(
      `/merchant/update/${props.selectedMerchantData.id}`,
      { business_scheme: scheme }
    ).then((res) => {
      if (res.data.business_scheme) {
        toast.success(`Your Business Scheme is ${res.data.business_scheme}`);
        window.location.replace(
          `${process.env.REACT_APP_FRONTEND_URL_ONBOARDING}/onboarding?id=${res.data.id}`
        );
      }
    });
  }

  return (
    <Modal show={true} backdrop='static' keyboard={false}>
      <Modal.Header>
        <Modal.Title>Choose Your Business Scheme</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* Products */}
        <Button
          variant='outline-dark'
          disabled={isLoading}
          style={{ width: '100%', margin: '10px 0 10px 0' }}
          onClick={() => update_scheme('Products and Services')}>
          {isLoading ? (
            <span>
              Processing...{'      '}
              <Spinner animation='border' variant='dark' size='sm' />
            </span>
          ) : (
            'Products and Services'
          )}
        </Button>

        {/* Subscriptions */}
        <Button
          variant='outline-dark'
          style={{ width: '100%', margin: '10px 0 10px 0' }}
          onClick={() => update_scheme('Subscriptions')}
          disabled={true}>
          Products, Services, and Subscriptions
          <span style={{ fontSize: '8px' }}> (Coming Soon)</span>
        </Button>

        {/* All Schemes */}
        <Button
          variant='outline-dark'
          style={{ width: '100%', margin: '10px 0 10px 0' }}
          onClick={() => update_scheme('All Schemes')}
          disabled={true}>
          Products, Services, Subscriptions, WEB3, Financing and Funding
          <span style={{ fontSize: '8px' }}> (Coming Soon)</span>
        </Button>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default SchemesListModal;
