/** @format */

import React from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function PhoneNumberInput(props) {
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".

  return (
    <>
      <PhoneInput
        international
        placeholder='Enter phone number'
        value={props.phoneNumber}
        onChange={props.setPhoneNumber}
        defaultCountry='JO'
        error={
          props.phoneNumber
            ? isValidPhoneNumber(props.phoneNumber)
              ? undefined
              : 'Invalid phone number'
            : 'Phone number required'
        }
      />

      {/* {props.phoneNumber && isValidPhoneNumber(props.phoneNumber) ? (
        <p style={{ color: 'green' }}>Phone Number: Valid Phone Number</p>
      ) : (
        <p style={{ color: 'red' }}>
          Phone Number: Invalid Phone Number it Should be Like +XXX XX XXX XXXX
        </p>
      )} */}
    </>
  );
}
export default React.memo(PhoneNumberInput);
