/** @format */

const merchantDetailModalCardsStyle = {
  mainSectionStyle: {
    marginBottom: '2rem',
    paddingInline: '2rem',
  },

  h4HeaderTitleSection: {
    fontWeight: 'bold',
    fontSize: '20px',
  },

  pTitleStyle: {
    color: 'rgb(104, 115, 133)',
  },

  checkBoxStyle: {
    marginRight: '1rem',
  },
};

export default merchantDetailModalCardsStyle;
