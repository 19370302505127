/** @format */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';

import AccountInformation from '../cards/merchantDetailModalCards/AccountInformation';
import ContactInformation from '../cards/merchantDetailModalCards/ContactInformation';
import MerchantBusinessDetails from '../cards/merchantDetailModalCards/MerchantBusinessDetails';
import MerchantContactInformationAndLocationDetails from '../cards/merchantDetailModalCards/MerchantContactInformationAndLocationDetails';
import MerchantInformation from '../cards/merchantDetailModalCards/MerchantInformation';
import MerchantStatusDetails from '../cards/merchantDetailModalCards/MerchantStatusDetails';
import MerchantSubscriptionDetails from '../cards/merchantDetailModalCards/MerchantSubscriptionDetails';
import PersonalDetails from '../cards/merchantDetailModalCards/PersonalDetails';
import VerificationDetails from '../cards/merchantDetailModalCards/VerificationDetails';
import AxiosInstanceOnboardingServer from '../../components/axios/AxiosInstanceOnboardingServer';

export default function MerchantDetailModal(props) {
  // Owner Details States
  const [firstLegalName, setFirstLegalName] = useState(
    props.ownerDetails.first_legal_name
  );
  const [lastLegalName, setLastLegalName] = useState(
    props.ownerDetails.last_legal_name
  );
  const [ownerEmail, setOwnerEmail] = useState(props.ownerDetails.email);
  const [phoneNumber, setPhoneNumber] = useState(
    `+${props.ownerDetails.phone_number}`
  );
  const [city, setCity] = useState(props.ownerDetails.city);
  const [country, setCountry] = useState(props.ownerDetails.country);
  const [address, setAddress] = useState(props.ownerDetails.address);

  // Owner DOB
  const [ownerDob, setOwnerDob] = useState('');
  function formatOwnerDOB() {
    if (props.ownerDetails.dob) {
      const months = [
        '',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      let date = props.ownerDetails.dob.split(' ');
      let dateString = `${date[3]}-0${months.indexOf(date[2])}-${date[1]}`;
      setOwnerDob(dateString);
    }
  }
  useEffect(() => {
    formatOwnerDOB();
  }, [props.ownerDetails.dob]);

  const [postalCode, setPostalCode] = useState(props.ownerDetails.postal_code);
  const [verificationCity, setVerificationCity] = useState(
    props.ownerDetails.verification_city
  );
  const [verificationCountry, setVerificationCountry] = useState(
    props.ownerDetails.verification_country
  );
  const [verificationExpireDate, setVerificationExpireDate] = useState(
    props.ownerDetails.verification_expire_date
  );
  const [verificationNationalId, setVerificationNationalId] = useState(
    props.ownerDetails.verification_national_id
  );
  const [ownerClearanceStatus, setOwnerClearanceStatus] = useState(
    props.ownerDetails.clearance_status
  );
  const [clearanceStatusDescription, setClearanceStatusDescription] = useState(
    props.ownerDetails.clearance_status_description
  );

  // Merchant Details States
  const [legalNameMerchant, setLegalNameMerchant] = useState(
    props.merchantDetails.legal_name
  );
  const [registrationNumberMerchant, setRegistrationNumberMerchant] = useState(
    props.merchantDetails.registration_number
  );
  const [websiteLink, setWebsiteLink] = useState(
    props.merchantDetails.website_link
  );
  // status   #1 waiting_business_scheme     #2 waiting_information    #3 pending_verification     #4 completed    #5 request_correction     #6 request_meeting    #7 blocked
  const [merchantStatus, setMerchantStatus] = useState(
    props.merchantDetails.status
  );
  const [statusDescriptionMerchant, setStatusDescriptionMerchant] = useState(
    props.merchantDetails.status_description
  );
  const [merchantClearanceStatus, setClearanceStatusMerchant] = useState(
    props.merchantDetails.clearance_status
  );
  const [
    clearanceStatusDescriptionMerchant,
    setClearanceStatusDescriptionMerchant,
  ] = useState(props.merchantDetails.clearance_status_description);
  const [subscriptionIDMerchant, setSubscriptionIDMerchant] = useState(
    props.merchantDetails.subscription_id
  );
  const [subscriptionPlanMerchant, setSubscriptionPlanMerchant] = useState(
    props.merchantDetails.subscription_plan
  );
  const [feesPercentageMerchant, setFeesPercentageMerchant] = useState(
    props.merchantDetails.fees_percentage
  );
  const [transactionFeeMerchant, setTransactionFeeMerchant] = useState(
    props.merchantDetails.transaction_fee
  );
  const [accountsLimit, setAccountsLimit] = useState(
    props.merchantDetails.accounts_limit
  );
  const [businessScheme, setBusinessScheme] = useState(
    props.merchantDetails.business_scheme
  );
  const [businessType, setBusinessType] = useState(
    props.merchantDetails.business_type
  );
  const [displayNameMerchant, setDisplayNameMerchant] = useState(
    props.merchantDetails.display_name
  );
  const [companyStructure, setCompanyStructure] = useState(
    props.merchantDetails.company_structure
  );
  const [supportEmailMerchant, setSupportEmailMerchant] = useState(
    props.merchantDetails.support_email
  );
  const [phoneNumberMerchant, setPhoneNumberMerchant] = useState(
    `+${props.merchantDetails.phone_number}`
  );
  const [countryMerchant, setCountryMerchant] = useState(
    props.merchantDetails.country
  );
  const [cityMerchant, setCityMerchant] = useState(props.merchantDetails.city);
  const [line1Merchant, setLine1Merchant] = useState(
    props.merchantDetails.line1
  );
  const [line2Merchant, setLine2Merchant] = useState(
    props.merchantDetails.line2
  );
  const [postalCodeMerchant, setPostalCodeMerchant] = useState(
    props.merchantDetails.postal_code
  );
  const [referralMerchant, setReferralMerchant] = useState(
    props.merchantDetails.referral
  );

  const [infoEditStatus, setInfoEditStatus] = useState(true);
  // To hold all correction fields
  const [statusCorrectionFields, setStatusCorrectionFields] = useState(
    props.merchantDetails.status_correction_fields
      ? props.merchantDetails.status_correction_fields
      : ''
  );
  const handleInfoEditStatus = (infoEditStatus) => {
    setInfoEditStatus(infoEditStatus);
    // if (!infoEditStatus) {
    //   setStatusCorrectionFields('');
    // }
  };

  // Function to hold all fields which checked
  const handleCorrectionFields = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    // Case 1: The user checks the box
    if (checked) {
      setStatusCorrectionFields((old) => old + value);
    }
    // Case 2: The user unchecks the box
    else {
      let newValue = statusCorrectionFields.replace(value, '');
      setStatusCorrectionFields(newValue);
    }
  };

  const handleAccountInfoSave = (e) => {
    e.preventDefault();

    let phone_number = phoneNumber.slice(1);
    AxiosInstanceOnboardingServer.put(
      `/onboarding-team/owner/update/${props.ownerDetails.id}`,
      {
        clearance_status: ownerClearanceStatus,
        clearance_status_description: clearanceStatusDescription,
        first_legal_name: firstLegalName,
        last_legal_name: lastLegalName,
        dob: ownerDob,
        email: ownerEmail,
        phone_number: phone_number,
        country: country,
        city: city,
        address: address,
        postal_code: postalCode,
        verification_national_id: verificationNationalId,
        verification_expire_date: verificationExpireDate,
        verification_city: verificationCity,
        verification_country: verificationCountry,
      }
    ).then((res) => {
      console.log('Update-owner', res.data);
    });

    let phone_number_merchants = phoneNumberMerchant.slice(1);
    AxiosInstanceOnboardingServer.put(
      `/onboarding-team/merchant/update/${props.merchantDetails.id}`,
      {
        status: merchantStatus,
        status_description: statusDescriptionMerchant,
        status_correction_fields: statusCorrectionFields,
        clearance_status: merchantClearanceStatus,
        clearance_status_description: clearanceStatusDescriptionMerchant,
        referral: referralMerchant,
        subscription_id: subscriptionIDMerchant,
        subscription_plan: subscriptionPlanMerchant,
        fees_percentage: feesPercentageMerchant,
        transaction_fee: transactionFeeMerchant,
        accounts_limit: accountsLimit,
        business_scheme: businessScheme,
        business_type: businessType,
        display_name: displayNameMerchant,
        legal_name: legalNameMerchant,
        registration_number: registrationNumberMerchant,
        company_structure: companyStructure,
        support_email: supportEmailMerchant,
        phone_number: phone_number_merchants,
        website_link: websiteLink,
        country: countryMerchant,
        city: cityMerchant,
        line1: line1Merchant,
        line2: line2Merchant,
        postal_code: postalCodeMerchant,
      }
    ).then((res) => {
      console.log('Update-merchant', res.data);
    });

    setInfoEditStatus(true);
  };

  return (
    <Modal
      fullscreen={true}
      backdrop='static'
      show={props.MerchantModalShow}
      onHide={() => props.setMerchantModalShow(!props.MerchantModalShow)}
      aria-labelledby='example-custom-modal-styling-title'>
      <Modal.Header closeButton style={{ paddingInline: '2rem' }}>
        <Modal.Title id='example-custom-modal-styling-title'>
          Owner & Merchant Details
        </Modal.Title>

        {/* buttons Edit & Save */}
        <Col
          style={{
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'end',
          }}>
          <Button
            variant={infoEditStatus ? 'primary' : 'danger'}
            style={{ marginRight: '1rem' }}
            onClick={() => handleInfoEditStatus(!infoEditStatus)}>
            {infoEditStatus ? 'Edit' : 'Cancel'}
          </Button>
          <Button
            variant='success'
            onClick={(e) => handleAccountInfoSave(e)}
            disabled={infoEditStatus}>
            Save
          </Button>
        </Col>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col lg={12}>
            {/* Start Owner Details */}
            <Row>
              <Row style={{ paddingRight: '0.5rem', margin: '1rem 0' }}>
                <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Owner Details
                </p>
              </Row>

              <Col lg={12}>
                {/* Account Information */}
                <AccountInformation
                  infoEditStatus={infoEditStatus}
                  ownerID={props.ownerDetails.id}
                  ownerCreated={props.ownerDetails.created}
                  ownerUpdated={props.ownerDetails.updated}
                  onboardingAttempts={props.ownerDetails.onboarding_attempts}
                  handleCorrectionFields={handleCorrectionFields}
                />

                {/* Personal Details*/}
                <PersonalDetails
                  infoEditStatus={infoEditStatus}
                  status_correction_fields={
                    props.merchantDetails.status_correction_fields
                  }
                  firstLegalName={firstLegalName}
                  setFirstLegalName={setFirstLegalName}
                  lastLegalName={lastLegalName}
                  setLastLegalName={setLastLegalName}
                  ownerDob={ownerDob}
                  setOwnerDob={setOwnerDob}
                  country={country}
                  setCountry={setCountry}
                  city={city}
                  setCity={setCity}
                  address={address}
                  setAddress={setAddress}
                  postalCode={postalCode}
                  setPostalCode={setPostalCode}
                  statusCorrectionFields={statusCorrectionFields}
                  handleCorrectionFields={handleCorrectionFields}
                />

                {/* Contact information*/}
                <ContactInformation
                  infoEditStatus={infoEditStatus}
                  ownerEmail={ownerEmail}
                  setOwnerEmail={setOwnerEmail}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  statusCorrectionFields={statusCorrectionFields}
                  handleCorrectionFields={handleCorrectionFields}
                />

                {/* Verification details*/}
                <VerificationDetails
                  infoEditStatus={infoEditStatus}
                  verificationNationalId={verificationNationalId}
                  setVerificationNationalId={setVerificationNationalId}
                  verificationExpireDate={verificationExpireDate}
                  setVerificationExpireDate={setVerificationExpireDate}
                  verificationCountry={verificationCountry}
                  setVerificationCountry={setVerificationCountry}
                  verificationCity={verificationCity}
                  setVerificationCity={setVerificationCity}
                  clearanceStatus={ownerClearanceStatus}
                  setClearanceStatus={setOwnerClearanceStatus}
                  clearanceStatusDescription={clearanceStatusDescription}
                  setClearanceStatusDescription={setClearanceStatusDescription}
                  handleCorrectionFields={handleCorrectionFields}
                />
              </Col>
            </Row>
            {/* End Owner Details */}

            {/* Start Merchant Details */}
            <Row>
              <Row style={{ paddingRight: '0.5rem', margin: '1rem 0' }}>
                <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Merchant Details
                </p>
              </Row>
              <Col lg={12}>
                {/* Merchant Information */}
                <MerchantInformation
                  infoEditStatus={infoEditStatus}
                  merchantID={props.merchantDetails.id}
                  referralMerchant={referralMerchant}
                  setReferralMerchant={setReferralMerchant}
                  createdMerchant={props.merchantDetails.created}
                  updatedMerchant={props.merchantDetails.updated}
                  websiteLink={websiteLink}
                  setWebsiteLink={setWebsiteLink}
                  statusCorrectionFields={statusCorrectionFields}
                  handleCorrectionFields={handleCorrectionFields}
                />

                {/* Merchant Status Details*/}
                <MerchantStatusDetails
                  infoEditStatus={infoEditStatus}
                  statusMerchant={merchantStatus}
                  setStatusMerchant={setMerchantStatus}
                  statusDescriptionMerchant={statusDescriptionMerchant}
                  setStatusDescriptionMerchant={setStatusDescriptionMerchant}
                  clearanceStatusMerchant={merchantClearanceStatus}
                  setClearanceStatusMerchant={setClearanceStatusMerchant}
                  clearanceStatusDescriptionMerchant={
                    clearanceStatusDescriptionMerchant
                  }
                  setClearanceStatusDescriptionMerchant={
                    setClearanceStatusDescriptionMerchant
                  }
                  tosAcceptance={props.merchantDetails.tos_acceptance}
                  stripeStatus={props.merchantDetails.stripe_status}
                  stripeStatusReason={
                    props.merchantDetails.stripe_status_reason
                  }
                  handleCorrectionFields={handleCorrectionFields}
                />

                {/* Merchant Subscription details*/}
                <MerchantSubscriptionDetails
                  infoEditStatus={infoEditStatus}
                  subscriptionIDMerchant={subscriptionIDMerchant}
                  setSubscriptionIDMerchant={setSubscriptionIDMerchant}
                  subscriptionPlanMerchant={subscriptionPlanMerchant}
                  setSubscriptionPlanMerchant={setSubscriptionPlanMerchant}
                  accountsLimit={accountsLimit}
                  setAccountsLimit={setAccountsLimit}
                  feesPercentageMerchant={feesPercentageMerchant}
                  setFeesPercentageMerchant={setFeesPercentageMerchant}
                  transactionFeeMerchant={transactionFeeMerchant}
                  setTransactionFeeMerchant={setTransactionFeeMerchant}
                  statusCorrectionFields={statusCorrectionFields}
                  handleCorrectionFields={handleCorrectionFields}
                />

                {/* Merchant Business details*/}
                <MerchantBusinessDetails
                  infoEditStatus={infoEditStatus}
                  businessScheme={businessScheme}
                  setBusinessScheme={setBusinessScheme}
                  businessType={businessType}
                  setBusinessType={setBusinessType}
                  displayNameMerchant={displayNameMerchant}
                  setDisplayNameMerchant={setDisplayNameMerchant}
                  legalNameMerchant={legalNameMerchant}
                  setLegalNameMerchant={setLegalNameMerchant}
                  companyStructure={companyStructure}
                  setCompanyStructure={setCompanyStructure}
                  registrationNumberMerchant={registrationNumberMerchant}
                  setRegistrationNumberMerchant={setRegistrationNumberMerchant}
                  statusCorrectionFields={statusCorrectionFields}
                  handleCorrectionFields={handleCorrectionFields}
                />

                {/* Merchant Contact information and location details*/}
                <MerchantContactInformationAndLocationDetails
                  infoEditStatus={infoEditStatus}
                  supportEmailMerchant={supportEmailMerchant}
                  setSupportEmailMerchant={setSupportEmailMerchant}
                  phoneNumberMerchant={phoneNumberMerchant}
                  setPhoneNumberMerchant={setPhoneNumberMerchant}
                  countryMerchant={countryMerchant}
                  setCountryMerchant={setCountryMerchant}
                  cityMerchant={cityMerchant}
                  setCityMerchant={setCityMerchant}
                  line1Merchant={line1Merchant}
                  setLine1Merchant={setLine1Merchant}
                  line2Merchant={line2Merchant}
                  setLine2Merchant={setLine2Merchant}
                  postalCodeMerchant={postalCodeMerchant}
                  setPostalCodeMerchant={setPostalCodeMerchant}
                  statusCorrectionFields={statusCorrectionFields}
                  handleCorrectionFields={handleCorrectionFields}
                />
              </Col>
            </Row>
            {/* End Merchant Details */}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
