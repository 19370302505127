/** @format */

import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import merchantDetailModalCardsStyle from '../../../styles/merchantDetailModalCardsStyle';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from '../../PhoneNumberInput';

import 'react-phone-number-input/style.css';

export default function ContactInformation(props) {
  return (
    <Form>
      <Row style={merchantDetailModalCardsStyle.mainSectionStyle}>
        <Col lg={12}>
          <h4 style={merchantDetailModalCardsStyle.h4HeaderTitleSection}>
            Contact information
          </h4>
          <hr />
        </Col>
        <Row>
          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='email'
                    value='owner-email,'
                    checked={props.statusCorrectionFields.includes(
                      'owner-email'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>Email</p>
              </Col>

              <Col>
                <Form.Control
                  defaultValue={props.ownerEmail}
                  disabled={props.infoEditStatus}
                  onChange={(e) => props.setOwnerEmail(e.target.value)}
                />
              </Col>
            </Row>
          </Col>

          <Col>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4} style={{ display: 'flex' }}>
                {!props.infoEditStatus && (
                  <Form.Check
                    style={merchantDetailModalCardsStyle.checkBoxStyle}
                    type='checkbox'
                    id='phone_number'
                    value='owner-phone_number,'
                    checked={props.statusCorrectionFields.includes(
                      'owner-phone_number'
                    )}
                    onChange={(e) => props.handleCorrectionFields(e)}
                  />
                )}
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Phone number
                </p>
              </Col>

              <Col>
                {!props.infoEditStatus ? (
                  <PhoneNumberInput
                    phoneNumber={props.phoneNumber}
                    setPhoneNumber={props.setPhoneNumber}
                    required
                  />
                ) : (
                  <Form.Control
                    defaultValue={props.phoneNumber}
                    disabled={props.infoEditStatus}
                    onChange={(e) => props.setPhoneNumber(e.target.value)}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}
