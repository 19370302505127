/** @format */

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Row, Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import BeatLoader from 'react-spinners/BeatLoader';

import OnBoarding from './main-pages/OnBoarding';
import PendingVerification from './main-pages/PendingVerification';
import RequestMeetingPage from './main-pages/RequestMeetingPage';
import OnBoardingTeam from './main-pages/OnBoardingTeam';
import NotFound from './main-pages/NotFound';

import AxiosInstanceUserServer from './components/axios/AxiosInstanceUserServer';
import Header from './components/Header';
import Footer from './components/Footer';
import RequestCorrection from './main-pages/RequestCorrection';

import LoginRedirection from './components/LoginRedirection';
import MerchantsListModal from './components/modals/MerchantsListModal';
import SchemesListModal from './components/modals/SchemesListModal';
import NewMerchant from './main-pages/NewMerchant';
import { ImageProvider } from './ImageContext';



function App() {
  // Function to clear complete cache data
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    clearCacheData();
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [loading]);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  // Get the Owner & Merchants Data from the Token
  const [tokenData, setTokenData] = useState({
    status: '',
    owner: '',
    merchants: [''],
  });
  useEffect(() => {
    getTokenData();
  }, []);
  function getTokenData() {
    AxiosInstanceUserServer.get('/account/token')
      .then((res) => {
        setTokenData(res.data);
        merchantRedirect(res.data.owner, res.data.merchants);
        if (res.data.owner.clearance_status === 'rejected') {
          window.location.replace(
            `${process.env.REACT_APP_FRONTEND_URL_USER}/rejected`
          );
        }
      })
      .catch(() => {
        window.location.replace(
          `${process.env.REACT_APP_FRONTEND_URL_USER}/login`
        );
      });
  }

  // Get the id from the Endpoint Query Parameter
  const [merchantsListModalShow, setMerchantsListModalShow] = useState(false);
  const location = window.location.search;
  const searchParams = new URLSearchParams(location);
  let idQuery = searchParams.get('id');

  function merchantRedirect(owner, merchants) {
    let merchantsIds = [];
    merchants.map((merchant) => {
      merchantsIds.push(merchant.id);
    });
    // If there is no Id || the Id not in the Merchant List
    if (!idQuery || !merchantsIds.includes(idQuery)) {
      setMerchantsListModalShow(true);
    } else {
      getSelectedMerchantData(idQuery, owner, merchants);
    }
  }

  // Pages Redirect
  const [schemesListModalShow, setSchemesListModalShow] = useState(false);
  const [selectedMerchantData, setSelectedMerchantData] = useState([]);
  const [merchantIdx, setMerchantIdx] = useState(0);

  var StringListEmail = `${process.env.REACT_APP_EMAIL_LIST}`;
  var ArrayListEmail = StringListEmail.split(',');

  function getSelectedMerchantData(idQuery, owner, merchants) {
    for (let i = 0; i < merchants.length; i++) {
      if (merchants[i].id === idQuery) {
        setSelectedMerchantData(merchants[i]);
        setMerchantIdx(i);
        if (!ArrayListEmail.includes(owner.email)) {
          if (merchants[i].status === 'waiting_business_scheme') {
            setSchemesListModalShow(true);
          } else if (
            window.location.pathname !== '/onboarding' &&
            (merchants[i].status === 'waiting_business_scheme' ||
              merchants[i].status === 'waiting_information')
          ) {
            window.location.replace(`/onboarding?id=${merchants[i].id}`);
          } else if (
            window.location.pathname !== '/request-correction' &&
            (merchants[i].status === 'request_correction' ||
              merchants[i].status === 'waiting_tos_acceptance')
          ) {
            window.location.replace(
              `/request-correction?id=${merchants[i].id}`
            );
          } else if (
            window.location.pathname !== '/pending-verification' &&
            merchants[i].status === 'pending_verification'
          ) {
            window.location.replace(
              `/pending-verification?id=${merchants[i].id}`
            );
          } else if (
            window.location.pathname !== '/request-meeting' &&
            merchants[i].status === 'request_meeting'
          ) {
            window.location.replace(`/request-meeting?id=${merchants[i].id}`);
          } else if (
            window.location.pathname !== '/new-merchant' &&
            merchants[i].status === 'new_merchant'
          ) {
            window.location.replace(`/new-merchant?id=${merchants[i].id}`);
          } else if (
            window.location.pathname !== '/blocked' &&
            merchants[i].status === 'blocked'
          ) {
            window.location.replace('/blocked');
          } else if (merchants[i].status === 'rejected') {
            window.location
              .replace`${process.env.REACT_APP_FRONTEND_URL_MENACART}/rejected?id=${merchants[i].id}`;
          } else if (merchants[i].status === 'completed') {
            window.location
              .replace(`${process.env.REACT_APP_FRONTEND_URL_MENACART}/dashboard?id=${merchants[i].id}`);
          }
          break;
        }
      }
    }
  }

  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'kvjrdr67',
  });
  // window.Intercom("update");

  return (
    <>
    <ImageProvider>
      {loading ? (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
            height: '100hv',
            marginTop: '330px',
          }}>
          <BeatLoader color='#304DAF' size='20px' />
        </div>
      ) : (
        <Row
          style={{
            backgroundColor: 'rgba(248, 249, 253, 1)',
            margin: '0',
            padding: '0',
          }}>
          {/* If the User is Not LoggedIn */}
          {tokenData.status.length && tokenData.status !== 'token is valid' ? (
            <LoginRedirection tokenData={tokenData} />
          ) : (
            <>
              {/* If the Link without Query Parameter Id */}
              {merchantsListModalShow ? (
                <MerchantsListModal
                  merchantsListModalShow={merchantsListModalShow}
                  setMerchantsListModalShow={setMerchantsListModalShow}
                  tokenData={tokenData}
                />
              ) : // If the Merchant Status === 'waiting_business_scheme'
                schemesListModalShow ? (
                  <SchemesListModal selectedMerchantData={selectedMerchantData} />
                ) : (
                  <Router>
                    <Header tokenData={tokenData} merchantIdx={merchantIdx} />
                    <ToastContainer hideProgressBar={true} newestOnTop={true} />

                    <Row style={{ margin: 'auto' }}>
                      <Container
                        id='top'
                        fluid
                        style={{
                          margin: '0',
                          padding: '0',
                          paddingTop: '2%',
                          backgroundColor: 'rgba(248, 249, 253, 1)',
                        }}>
                        <Routes>
                          {/* #1 Onboarding Page */}
                          <Route
                            path='/onboarding'
                            element={
                              <OnBoarding
                                tokenData={tokenData}
                                selectedMerchantData={selectedMerchantData}
                              />
                            }></Route>
                          {/* #2 Pending Verification Page */}
                          <Route
                            path='/pending-verification'
                            element={
                              <PendingVerification
                                tokenData={tokenData}
                                selectedMerchantData={selectedMerchantData}
                              />
                            }></Route>

                          {/* #3 Request Meeting Page */}
                          <Route
                            path='/request-meeting'
                            element={
                              <RequestMeetingPage
                                tokenData={tokenData}
                                selectedMerchantData={selectedMerchantData}
                              />
                            }></Route>
                          {/* #4 OnBoardingTeam Page */}
                          <Route
                            path='/onboarding-team'
                            element={
                              <OnBoardingTeam
                                tokenData={tokenData}
                                selectedMerchantData={selectedMerchantData}
                              />
                            }></Route>
                          {/* Request Correction */}
                          <Route
                            path='/request-correction'
                            element={
                              <RequestCorrection
                                tokenData={tokenData}
                                selectedMerchantData={selectedMerchantData}
                              />
                            }></Route>

                          {/* New Merchant */}
                          <Route
                            path='/new-merchant'
                            element={
                              <NewMerchant
                                tokenData={tokenData}
                                selectedMerchantData={selectedMerchantData}
                              />
                            }></Route>


                          {/* 404 Page */}
                          <Route path='*' element={<NotFound />}></Route>
                        </Routes>

                      </Container>
                    </Row>
                    <Row id='contactus' style={{ margin: 'auto' }}>
                      <Footer />
                    </Row>
                  </Router>
                )}
            </>
          )}
        </Row>
      )}
      </ImageProvider>
    </>
  );
}

export default App;
