/** @format */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap';
import { FaLinkedinIn, FaInstagram, FaFacebookSquare } from 'react-icons/fa';

import './Footer.css';

function Footer() {
  return (
    <Container
      fluid
      style={{
        backgroundColor: 'rgba(248, 249, 253, 1)',
        color: '#304352',
        fontFamily: 'sans-serif',
        margin:'0',
        padding:'0',
      
      }}
    >
      {/* Row One */}
      <Row
        style={{
          margin:'auto',
          padding:'0',
          borderBottom: '1.5px solid rgba(77, 102, 243, 1)',
          width: '80%',
          position: 'relative',
        }}
      >
        <Col lg={5} md={7} sm={8} style={{ fontSize: '110%', marginTop: '2%' }}>
          {/* col One */}

          <Row>
            <h6>
              <strong  className="FINTESA">FINTESA</strong>
            </h6>
          </Row>
          <Row className="FINTESA" style={{ marginTop: '2%', textAlign: 'justify' }}>
            <p >
            Finesea is a SaaS platform that allows any business to 
  compliantly collect payments in over 200 countries for their products,
  and services - in just 10 minutes.
            </p>
          </Row>
          <Row>
            <span
              style={
                window.innerWidth > 576
                  ? {}
                  : {
                      color: '#304352',
                      marginBottom: '50px',
                    }
              }
            >
              {/* Linkedin */}
              <a
                href='https://www.linkedin.com/company/fintesa-firm/'
                style={
                  window.innerWidth > 576
                    ? {
                        textDecoration: 'none',
                        color: '#304352',
                        marginRight: '10px',
                      }
                    : {
                        textDecoration: 'none',
                        color: '#304352',
                        marginRight: '8px',
                      }
                }
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaLinkedinIn size={25} />
              </a>
              {/* Instagram */}
              <a
                href='https://www.instagram.com/fintesa.co/'
                style={
                  window.innerWidth > 576
                    ? {
                        textDecoration: 'none',
                        color: '#304352',
                        marginRight: '10px',
                      }
                    : {
                        textDecoration: 'none',
                        color: '#304352',
                        marginRight: '8px',
                      }
                }
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaInstagram size={25} />
              </a>
              {/* Facebook */}
              <a
                href='https://www.facebook.com/Fintesa-108434338646751'
                style={
                  window.innerWidth > 576
                    ? {
                        textDecoration: 'none',
                        color: '#304352',
                        marginRight: '10px',
                      }
                    : {
                        textDecoration: 'none',
                        color: '#304352',
                        marginRight: '8px',
                      }
                }
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaFacebookSquare size={25} />
              </a>
            </span>
          </Row>
        </Col>

        {/* Empty Col for Spacing */}
        <Col lg={1} md={1}></Col>

        {/* Col Two */}
        <Col lg={6} md={12} sm={12} style={{ marginTop: '2%', display: "flex", flexDirection: "column", gap: "1em" }}>
          {/* Contact Us Row */}
          <Row style={{ marginTop: "0" }}>
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <div>
                <p className="ContactUs" style={{marginBottom: "0.75em"}}><strong>Contact Us</strong></p>
                <p className="ContactUs" style={{margin: "0"}}>  You can reach us through the chatbox at the bottom right
                <img style={{ width: "1.5em", height: "1.5em", marginLeft: "1ch" }} src={ process.env.PUBLIC_URL + "/images/intercom-icon-27.jpg" } alt="intercom chatbot icon " /><br/>
                if you have any questions about how to start or for any technical support.
                </p>
              </div>
            </div>
          </Row>

          <Row>
              <p className="ContactUs">
                Our services are FDIC Insured{' '}
                {/* https://stripe.com/treasury#:~:text=Stripe%20Treasury%20provides%20the%20modular,*%2C%20all%20within%20your%20platform */}
                with up to $250,000. For more
                <a
                  href='https://www.fdic.gov/resources/deposit-insurance/brochures/insured-deposits/'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ textDecoration: 'none', color: '#6495ED' }}
                >
                  {' '}
                  information.
                </a>
              </p>
            
          </Row>
        </Col>
      </Row>

      {/* Row Two */}
      <Row style={{ fontSize: '90%', position: 'relative' }}>
        <Col sm={4} style={{ display: 'flex' }}>
          <p style={{ margin: 'auto', textAlign: 'center' }}>
            Copyright @{new Date().getFullYear()} Fintesa
          </p>
        </Col>

        <Col
          sm={8}
          style={{
            marginTop: '0%',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Navbar
            style={{
              margin: '0',
              padding:'0',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >

            <Nav
              className=''
              style={{
                color: '#304352',
                paddingTop: '0px',
                justifyContent: 'center',
                textAlign: 'center',
                width: '80%',
                margin: '0',
              padding:'0',
              }}
            >
              <Row lg={8} md={6} sm={3} sx={3}>
                <Col>
                  <NavLink
                    className='w3-bar-item w3-button w3-hover-none w3-border-white w3-bottombar w3-hover-border-blue w3-hover-text-blue'
                    to='/'
                    style={
                      window.innerWidth > 576
                        ? { padding: '16px' }
                        : { padding: '8px' }
                    }
                  >
                    Home
                  </NavLink>
                </Col>
                {/* <NavLink
                className='w3-bar-item w3-button w3-hover-none w3-border-white w3-bottombar w3-hover-border-blue w3-hover-text-blue'
                to='/demo'>
                Demo
              </NavLink>*/}

                <Col>
                  <NavLink
                    className='w3-bar-item w3-button w3-hover-none w3-border-white w3-bottombar w3-hover-border-blue w3-hover-text-blue'
                    to='/FAQs'
                    style={
                      window.innerWidth > 576
                        ? { padding: '16px' }
                        : { padding: '4px' }
                    }
                  >
                    FAQs
                  </NavLink>
                </Col>

                <Col>
                  <NavLink
                    className='w3-bar-item w3-button w3-hover-none w3-border-white w3-bottombar w3-hover-border-blue w3-hover-text-blue'
                    to='/pricing'
                    style={
                      window.innerWidth > 576
                        ? { padding: '16px' }
                        : { padding: '4px' }
                    }
                  >
                    Pricing
                  </NavLink>
                </Col>
                <Col>
                  <Nav.Link
                    className='w3-bar-item w3-button w3-hover-none w3-border-white w3-bottombar w3-hover-border-blue w3-hover-text-blue'
                    to='/terms-and-conditions'
                    href={`${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}/terms-and-conditions`}
                    style={
                      window.innerWidth > 576
                        ? { padding: '16px' }
                        : { padding: '4px' }
                    }
                  >
                    Legal
                  </Nav.Link>
                </Col>
                <Col>
                  <NavLink
                    className='w3-bar-item w3-button w3-hover-none w3-border-white w3-bottombar w3-hover-border-blue w3-hover-text-blue'
                    to='/prohibited-business-types'
                    style={
                      window.innerWidth > 576
                        ? { padding: '16px' }
                        : { padding: '4px' }
                    }
                  >
                    Prohibited Business Types
                  </NavLink>
                </Col>
              </Row>
            </Nav>
            {/* </Container> */}
          </Navbar>
        </Col>

        {/* Row to Scrolling Top */}
        <Row className='d-none d-lg-block'></Row>
      </Row>
    </Container>
  );
}
export default React.memo(Footer);
