/** @format */

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';

import { Button } from 'react-bootstrap';
import MerchantDetailModal from '../modals/MerchantDetailModal';

export default function OnBoardingListTable(props) {
  const [MerchantModalShow, setMerchantModalShow] = useState(false);

  const [ownerDetails, setOwnerDetails] = useState({});
  const [merchantDetails, setMerchantDetails] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {/* Detailed Modal */}
      {MerchantModalShow && (
        <MerchantDetailModal
          MerchantModalShow={MerchantModalShow}
          setMerchantModalShow={setMerchantModalShow}
          ownerDetails={ownerDetails}
          merchantDetails={merchantDetails}
        />
      )}

      <Paper
        sx={{
          width: '100%',
          margin: '0',
          padding: '0 0 0 0',
        }}>
        <TableContainer sx={{ maxHeight: 1200 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell align='center' style={{ width: '20%' }}>
                  Owner Id & Email
                </TableCell>
                <TableCell
                  align='center'
                  style={{
                    width: '10%',
                    borderRight: '1px solid black',
                  }}>
                  Owner Clearance
                </TableCell>

                <TableHead>
                  <TableRow>
                    <TableCell align='center' style={{ width: '20%' }}>
                      Merchant Id & Display Name
                    </TableCell>
                    <TableCell align='center' style={{ width: '10%' }}>
                      Merchant Status
                    </TableCell>
                    <TableCell align='center' style={{ width: '10%' }}>
                      Subscription Plan
                    </TableCell>
                    <TableCell align='center' style={{ width: '10%' }}>
                      Fees
                    </TableCell>
                    <TableCell align='center' style={{ width: '10%' }}>
                      Created Date
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ width: '10%' }}></TableCell>
                  </TableRow>
                </TableHead>
              </TableRow>
            </TableHead>

            <TableBody>
              {props.allData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, indx) => (
                  <TableRow key={item.id}>
                    <TableCell align='center' style={{ width: '20%' }}>
                      <p>{item.id}</p>
                      <p>{item.email}</p>
                    </TableCell>

                    <TableCell
                      align='center'
                      style={{ width: '10%', borderRight: '1px solid black' }}>
                      <Tooltip
                        title={
                          item.clearance_status_description
                            ? item.clearance_status_description
                            : 'Empty Clearance Status Description'
                        }
                        arrow>
                        <p>{item.clearance_status}</p>
                      </Tooltip>
                    </TableCell>

                    <TableBody style={{ width: '75%' }}>
                      {props.allData[indx].merchants.map((merchant, id) => {
                        return (
                          <TableRow>
                            <TableCell align='center' style={{ width: '20%' }}>
                              <p>{merchant.id}</p>
                              <Tooltip
                                title={
                                  merchant.legal_name
                                    ? merchant.legal_name
                                    : 'Legal Name not Provided'
                                }
                                arrow>
                                <p>{merchant.display_name}</p>
                              </Tooltip>
                            </TableCell>
                            <TableCell align='center' style={{ width: '10%' }}>
                              <p>{merchant.status}</p>
                            </TableCell>
                            <TableCell align='center' style={{ width: '10%' }}>
                              <p> {merchant.subscription_plan}</p>
                            </TableCell>
                            <TableCell align='center' style={{ width: '10%' }}>
                              <p>
                                {' '}
                                {merchant.fees_percentage} +{' '}
                                {merchant.transaction_fee}
                              </p>
                            </TableCell>
                            <TableCell align='center' style={{ width: '10%' }}>
                              <p> {merchant.created}</p>
                            </TableCell>
                            <TableCell align='center' style={{ width: '10%' }}>
                              <Button
                                size='sm'
                                onClick={() => {
                                  setMerchantModalShow(true);
                                  setOwnerDetails(item);
                                  setMerchantDetails(merchant);
                                }}>
                                Details
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[3, 5, 10]}
          component='div'
          count={props.allData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
