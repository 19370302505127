/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { storage } from '../firebase';
import { ref, uploadBytes, listAll, getDownloadURL } from 'firebase/storage';
import DotLoader from 'react-spinners/DotLoader';

function PendingVerification(props) {
  // Function to clear complete cache data
  useEffect(() => {
    clearCacheData();
  }, []);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const [showInformation, setShowInformation] = useState(false);

  // Owner DOB
  const [ownerDob, setOwnerDob] = useState('');
  function formatOwnerDOB() {
    if (props.tokenData.owner.dob) {
      const months = [
        '',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      let date = props.tokenData.owner.dob.split(' ');
      let dateString = `${date[3]}-0${months.indexOf(date[2])}-${date[1]}`;
      setOwnerDob(dateString);
    }
  }
  useEffect(() => {
    formatOwnerDOB();
  }, [props.tokenData]);

  // to display tha image on the page =>
  // const [imageList, setImageList] = useState([]);
  // const imageListRef = ref(
  //   storage,
  //   `${props.tokenData.owner.id}/${props.selectedMerchantData.id}/VerificationDocument`
  // );

  // useEffect(() => {
  //   listAll(imageListRef).then((response) => {
  //     response.items.forEach((item) => {
  //       getDownloadURL(item).then((url) => {
  //         setImageList(url);
  //       });
  //     });
  //   });
  // }, []);

  return (
    <>
      {/* {imageList.map((url) => {
        return <img src={url} alt={url} />;
      })} */}
      <Row
        style={{
          margin: '0',
          padding: '0',
          justifyContent: 'center',
          textAlign: 'center',
        }}>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: '48px',
            color: 'rgb(18, 35, 60)',
          }}>
          Pending Verification
        </p>

        <h3
          style={{
            marginTop: '100px',
            fontWeight: 'bold',
          }}>
          Your account information is being reviewed<br></br>once approved you
          will be redirected to your dashboard.
        </h3>
        <p style={{ fontSize: '16px' }}>
          Keep in mind we will issue a credit amount for the subscription
          onboarding period.<br></br>You will recieve an email with the updates soon!
        </p>

        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
            height: '90hv',
            marginTop: '10px',
          }}>
          <DotLoader color='#304DAF' size='80px' />
        </div>
      </Row>


      <Row
        style={{
          margin: '15px 0 5px 0',
          padding: '0',
          justifyContent: 'center',
          textAlign: 'center',
        }}>
        <Button
          variant='outline-secondary'
          className='Loginbutton Lbutton'
          style={{ width: '32%' }}
          onClick={() => {
            setShowInformation(!showInformation);
          }}>
          {!showInformation ?
            "Show Your Information"
            : "Hide Your Information"
          }
        </Button>
      </Row>


      {showInformation && (
        <>

          <p style={{ fontSize: '30px', padding: '10px' }}>
            Submitted Application
          </p>

          <Form style={{ padding: '0', margin: '0' }}>
            <fieldset disabled>
              <Row style={{ margin: '0', padding: '0' }}>
                {/* Owner Data */}
                <Col>
                  {/* First Name */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      name='first name'
                      placeholder='first name'
                      value={props.tokenData.owner.first_legal_name}
                    />
                    <label>First Name</label>
                  </Form.Floating>

                  {/* Last Name */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      name='lastName'
                      placeholder='last name'
                      value={props.tokenData.owner.last_legal_name}
                    />
                    <label>Last Name</label>
                  </Form.Floating>

                  {/* DOB */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      name='dob'
                      placeholder='Date of Birth'
                      value={ownerDob}
                    />
                    <label>Date of Birth</label>
                  </Form.Floating>

                  {/* Email */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='email'
                      name='email'
                      placeholder='email'
                      value={props.tokenData.owner.email}
                    />
                    <label>Email</label>
                  </Form.Floating>

                  {/* Country */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      name='country'
                      placeholder='Country'
                      value={props.tokenData.owner.country}
                    />
                    <label>Country of Issuing</label>
                  </Form.Floating>
                  {/* country of Residence */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      name='country'
                      placeholder='Country'
                      value={props.tokenData.owner.country_of_residence}
                    />
                    <label>Country of Residence</label>
                  </Form.Floating>

                  {/* City */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      name='city'
                      placeholder='City'
                      value={props.tokenData.owner.city}
                    />
                    <label>City</label>
                  </Form.Floating>

                  {/* Phone Number */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      name='phone_number'
                      placeholder='Phone Number'
                      value={`+${props.tokenData.owner.phone_number}`}
                    />
                    <label>Phone Number</label>
                  </Form.Floating>
                </Col>

                {/* Merchant Data */}
                <Col>
                  {/* Business Type */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      name='business_type'
                      placeholder='Business Type'
                      value={props.selectedMerchantData.business_type}
                    />
                    <label>Business Type</label>
                  </Form.Floating>

                  {/* Display Name */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      name='display_name'
                      placeholder='Display Name'
                      value={props.selectedMerchantData.display_name}
                    />
                    <label>Display Name</label>
                  </Form.Floating>

                  {/* Support Email */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='email'
                      name='support_email'
                      placeholder='Support Email'
                      value={props.selectedMerchantData.support_email}
                    />
                    <label>Support Email</label>
                  </Form.Floating>

                  {/* Merchant Phone Number */}
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      name='merchant_phone_number'
                      placeholder='Merchant Phone Number'
                      value={`+${props.selectedMerchantData.phone_number}`}
                    />
                    <label>Merchant Phone Number</label>
                  </Form.Floating>

                  {props.selectedMerchantData.business_type === 'company' && (
                    <>
                      {/* Registration Number */}
                      <Form.Floating className='mb-3'>
                        <Form.Control
                          type='text'
                          name='registration_number'
                          placeholder='Registration Number'
                          value={props.selectedMerchantData.registration_number}
                        />
                        <label>Registration Number</label>
                      </Form.Floating>

                      {/* Legal Name */}
                      <Form.Floating className='mb-3'>
                        <Form.Control
                          type='text'
                          name='legal_name'
                          placeholder='Legal Name'
                          value={props.selectedMerchantData.legal_name}
                        />
                        <label>Legal Name</label>
                      </Form.Floating>

                      {/* Company Structure */}
                      <Form.Floating className='mb-3'>
                        <Form.Control
                          type='text'
                          name='company_structure'
                          placeholder='Company Structure'
                          value={props.selectedMerchantData.company_structure}
                        />
                        <label>Company Structure</label>
                      </Form.Floating>

                      {/* Country */}
                      <Form.Floating className='mb-3'>
                        <Form.Control
                          type='text'
                          name='merchant_country'
                          placeholder='Merchant Country'
                          value={props.selectedMerchantData.country}
                        />
                        <label>Merchant Country</label>
                      </Form.Floating>

                      {/* City */}
                      <Form.Floating className='mb-3'>
                        <Form.Control
                          type='text'
                          name='merchant_city'
                          placeholder='Merchant City'
                          value={props.selectedMerchantData.city}
                        />
                        <label>Merchant City</label>
                      </Form.Floating>

                      {/* Merchant Address Line1 */}
                      <Form.Floating className='mb-3'>
                        <Form.Control
                          type='text'
                          name='line1'
                          placeholder='Line1'
                          value={props.selectedMerchantData.line1}
                        />
                        <label>Line1</label>
                      </Form.Floating>

                      {/* Merchant Address Line2 */}
                      <Form.Floating className='mb-3'>
                        <Form.Control
                          type='text'
                          name='line2'
                          placeholder='Line2'
                          value={props.selectedMerchantData.line2}
                        />
                        <label>Line2</label>
                      </Form.Floating>

                      {/* Merchant Postal Code */}
                      <Form.Floating className='mb-3'>
                        <Form.Control
                          type='text'
                          name='postal_code'
                          placeholder='Postal Code'
                          value={props.selectedMerchantData.postal_code}
                        />
                        <label>Postal Code</label>
                      </Form.Floating>
                    </>
                  )}
                </Col>
              </Row>
            </fieldset>
          </Form>
        </>
      )}




    </>
  );
}

export default PendingVerification;
