/** @format */
/* eslint-disable array-callback-return */

import { Modal, Button } from 'react-bootstrap';

function MerchantsListModal(props) {
  function merchantRedirect(merchant) {
    if (props.tokenData.owner.clearance_status === 'rejected') {
      window.location.replace(
        `${process.env.REACT_APP_FRONTEND_URL_USER}/rejected`
      );
    } else if (
      merchant.status === 'waiting_business_scheme' ||
      merchant.status === 'waiting_information'
    ) {
      window.location.replace(`/onboarding?id=${merchant.id}`);
    } else if (
      merchant.status === 'request_correction' ||
      merchant.status === 'waiting_tos_acceptance'
    ) {
      window.location.replace(`/request-correction?id=${merchant.id}`);
    } else if (merchant.status === 'pending_verification') {
      window.location.replace(`/pending-verification?id=${merchant.id}`);
    } else if (merchant.status === 'request_meeting') {
      window.location.replace(`/request-meeting?id=${merchant.id}`);
    } else if (merchant.status === 'blocked') {
      window.location.replace('/blocked');
    } else if (merchant.status === 'rejected') {
      window.location
        .replace`${process.env.REACT_APP_FRONTEND_URL_MENACART}/rejected?id=${merchant.id}`;
    } else if (merchant.status === 'completed') {
      window.location.replace(
        `${process.env.REACT_APP_FRONTEND_URL_MENACART}/dashboard?id=${merchant.id}`
      );
    }
  }

  return (
    <Modal
      backdrop='static'
      keyboard={false}
      show={props.merchantsListModalShow}
      onHide={() =>
        props.setMerchantsListModalShow(!props.merchantsListModalShow)
      }>
      <Modal.Header>
        <Modal.Title>Choose a Merchant</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {props.tokenData.merchants.map((merchant, idx) => (
          <Button
            key={merchant.id}
            variant='outline-dark'
            style={{ width: '100%' }}
            onClick={() => merchantRedirect(merchant)}>
            {merchant.display_name
              ? merchant.display_name
              : `Merchant ${idx + 1}`}
            , {merchant.status}
          </Button>
        ))}
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default MerchantsListModal;
