/** @format */

import React, { useState } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import merchantDetailModalCardsStyle from '../../../styles/merchantDetailModalCardsStyle';

export default function MerchantStatusDetails(props) {
  return (
    <Form>
      <Row style={merchantDetailModalCardsStyle.mainSectionStyle}>
        <Col lg={12}>
          <h4 style={merchantDetailModalCardsStyle.h4HeaderTitleSection}>
            Merchant Status details
          </h4>
          <hr />
        </Col>
        <Row>
          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>Status</p>
              </Col>
              <Col>
                <Row>
                  <Form.Select
                    value={props.statusMerchant}
                    disabled={props.infoEditStatus}
                    size='sm'
                    style={{ width: '95%', marginLeft: '10px' }}
                    onChange={(e) => props.setStatusMerchant(e.target.value)}>
                    <option value={'waiting_business_scheme'}>
                      Waiting business scheme
                    </option>
                    <option value={'waiting_information'}>
                      Waiting information
                    </option>
                    <option value={'pending_verification'}>
                      Pending verification
                    </option>
                    <option value={'completed'}>Completed</option>
                    <option value={'request_correction'}>
                      Request correction
                    </option>
                    <option value={'request_meeting'}>Request meeting</option>
                    <option value={'blocked'}>Blocked</option>
                  </Form.Select>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Status Description
                </p>
              </Col>
              <Col>
                <Form.Control
                  as='textarea'
                  defaultValue={props.statusDescriptionMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setStatusDescriptionMerchant(e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Clearance Status
                </p>
              </Col>
              <Col>
                <Row>
                  <Form.Select
                    value={props.clearanceStatusMerchant}
                    disabled={props.infoEditStatus}
                    size='sm'
                    style={{ width: '95%', marginLeft: '10px' }}
                    onChange={(e) =>
                      props.setClearanceStatusMerchant(e.target.value)
                    }>
                    <option value={'not_started'}>Not started</option>
                    <option value={'in_progress'}>In progress</option>
                    <option value={'completed'}>Completed</option>
                    <option value={'need_extra_check'}>Need extra check</option>
                    <option value={'rejected'}>Rejected</option>
                  </Form.Select>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Clearance Status Description
                </p>
              </Col>
              <Col>
                <Form.Control
                  as='textarea'
                  defaultValue={props.clearanceStatusMerchant}
                  disabled={props.infoEditStatus}
                  onChange={(e) =>
                    props.setClearanceStatusMerchant(e.target.value)
                  }
                />
              </Col>
            </Row>
          </Col>

          <Col lg={6}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Tos Acceptance
                </p>
              </Col>
              <Col>
                <Row>
                  <Form.Control
                    value={props.tosAcceptance}
                    disabled={true}
                    size='sm'
                    style={{ width: '95%', marginLeft: '10px' }}
                  />
                </Row>
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Stripe Status
                </p>
              </Col>
              <Col>
                <Form.Control
                  defaultValue={props.stripeStatus}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '1rem' }}>
              <Col lg={4}>
                <p style={merchantDetailModalCardsStyle.pTitleStyle}>
                  Stripe Status Reasons
                </p>
              </Col>
              <Col>
                <Form.Control
                  as='textarea'
                  defaultValue={props.stripeStatusReason}
                  disabled={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}
