/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Row, Spinner } from 'react-bootstrap';

function LoginRedirection(props) {
  useEffect(() => {
    setTimeout(() => {
      if (props.tokenData.status !== 'token is valid') {
        window.location.replace(
          `${process.env.REACT_APP_FRONTEND_URL_USER}/login`
        );
      }
    }, 5000);
  }, []);

  return (
    <>
      <Row
        style={{
          justifyContent: 'center',
          textAlign: 'center',
          marginBottom: '20%',
          marginTop: '10%',
          backgroundColor: 'rgba(248, 249, 253, 1)',
        }}>
        <Row>
          <h1 style={{ marginBottom: '3%' }}>You Need to Login</h1>
          <h5>You will be redirected to the Login page after 5 seconds</h5>
          <Spinner
            animation='border'
            role='status'
            variant='primary'
            style={{ marginLeft: '50%' }}>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </Row>

        <Row>
          <p>
            If your browser doesn't work,{' '}
            <a href={`${process.env.REACT_APP_FRONTEND_URL_USER}/login`}>
              Click here
            </a>{' '}
            to go to the Login page
          </p>
        </Row>
      </Row>
    </>
  );
}
export default React.memo(LoginRedirection);
